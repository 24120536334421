import React, { useEffect, useState } from 'react'
import utils from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import '../css/Pedido.css'
import CardGrupoVariante from './CardGrupoVariante';
import utilsVariantes from '../utils/utilsVariantes';
import utilsCarrito from '../utils/utilsCarrito';
import msgToask from '../utils/msgToask';
import Header from './Header';

const server = process.env.REACT_APP_SERVER

function Pedido(props) {
    const { setPantalla, setCarrito, carrito } = props;
    const navigate = useNavigate()
    const [variantes, setVariantes] = useState([]);
    const [variantesApp,setVariantesApp] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [pagina, setPagina] = useState(0);
    const [descripcion, setDescripcion] = useState('');
    const [costo, setCosto] = useState(0);
    const [cantidad, setCantidad] = useState(0);
    const [cantidadM, setCantidadM] = useState(0);
    const [idMenu, setIdMenu] = useState(0);
    const [idPedido, setIdPedido] = useState(0);
    const [actualizarC, setActualizarC] = useState(false)
    const siquientePagina = (e) => {
        e.preventDefault()
        if (cantidad === 0) {
            if (pagina === grupos.length - 1) {
                agregarProducto();
            } else {
                const elegido = (JSON.parse(localStorage.getItem('elegido')))[0]
                setCantidad(elegido.cantidad)
                setPagina(pagina + 1)
                setActualizarC(!actualizarC)
            }
        } else {
            msgToask.msgError("Elija las porciones por favor!!!");
        }
    }
    const paginaAnterior = () => {
        if (pagina == 0) {
            setPantalla(1);
            localStorage.removeItem('variante')
        } else {
            if (pagina !== 0) {
                setActualizarC(!actualizarC)
                setPagina(pagina - 1)
            }
        }
    }
    const agregarProducto = () => {
        setCarrito(carrito + 1);
        utilsCarrito.agregarACarrito();
        navigate("/miPedido", { replace: true });
    }
    useEffect(() => {
        const menuVariante = localStorage.getItem('variante');
        if (menuVariante === null) {
            if(localStorage.getItem('elegido')!==null && localStorage.getItem('elegido')!==undefined){
                const elegido = (JSON.parse(localStorage.getItem('elegido')))[0]
                setIdPedido(parseInt(elegido.idPedido));
                setIdMenu(parseInt(elegido.idMenu));
                var dato = utils.getMenuSeleccionado(parseInt(elegido.idMenu))
                var menuApp = JSON.parse(localStorage.getItem('menuApp')).filter((item)=>item.ID_MENU==parseInt(elegido.idMenu))
                if(localStorage.getItem('filtroSucursal')==9){
                    setVariantesApp(menuApp[0].VARIANTES);
                }
                console.log(dato);
                var gr = []
                dato.rows.map((item) => {
                    gr.push(item.GRUPO)
                })
                const dataArr = new Set(gr);
                let result = [...dataArr];
                setGrupos(result);
                setVariantes(dato.rows);
                setDescripcion(dato.DESCRIPCION)
                setCosto(elegido.precioT)
                setCantidad(elegido.cantidad)
                setCantidadM(elegido.cantidad)
                localStorage.setItem('variante', JSON.stringify([]))
            }else{
                setPantalla(0);
            }
        } else {
            if (utilsVariantes.exiteGrupo(grupos[pagina])) {
                //console.log("actualizar cantidades elegidas",pagina)
                setCantidad(cantidadM - utilsVariantes.setCantidad(grupos[pagina]))
            }
        }
    }, [actualizarC]);
    return (
        <div>
            <Header pagina={1} title="Contenido" callback={() => paginaAnterior()} cantidad={cantidad} />
            <div className='container-fluid col-sm-12 col-md-6 col-xl-4'>
                <div className='overflow-auto miOverFlow py-1'>
                    <div className='container-fluid'>
                        <div className="row row-cols-1 g-2 gx-2">
                            {variantes.map((item,index) => {
                                var nameImgInit = item.ID_PRODUCTO_VARIANTE + (item.DESCRIPCION).replace(/ /g, "");
                                if (item.GRUPO == grupos[pagina]) {
                                    if((localStorage.getItem('filtroSucursal')!=9)||(localStorage.getItem('filtroSucursal')==9&&variantesApp.indexOf(item.ID_PRODUCTO_VARIANTE)!=-1)){
                                        return (
                                            <CardGrupoVariante item={item} img={nameImgInit.replace('.','')} tipo={pagina}
                                            cantProducto={cantidad} setCantProducto={setCantidad} 
                                            grupo={grupos[pagina]} idMenu={idMenu} 
                                            idPedido={idPedido} key={item.ID_PRODUCTO_VARIANTE} />
                                        )
                                    }
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className='container-fluid col-sm-12 col-md-6 col-xl-4 fixed-bottom py-1'>
                    <div className='container-fluid text-center m-0 p-0'>
                        <button className='btn btn-sm text-white fw-bold  rounded-pill w-100 botonNaranja'
                            onClick={siquientePagina}>
                            Siguiente <i className="bi bi-caret-right-fill"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pedido
