import React, { useEffect, useState, useSyncExternalStore } from 'react'
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import msgToask from '../utils/msgToask';
import Modal from 'react-bootstrap/Modal';
import PopupLoader from './utils/PopupLoader';
import utilsRecervas from '../utils/utilsRecervas';
import MsgAlertas from './utils/MsgAlertas';
import {saveAs} from 'file-saver';
const server = process.env.REACT_APP_SERVER;

function QrPagoVista() {
    const navigate = useNavigate()
    const [idPago, setIdPago] = useState('');
    const [showNoti, setShowNoti] = useState(false);
    const [estado, setEstado] = useState({});
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState('');
    const [idTelefonico, setIdTelefonico] = useState('');
    const [message, setMessage] = useState('');
    const [alerta, setAlerta] = useState(false);
    const [regional, setRegional] = useState('');
    const [conDocumento,setConDocumento] = useState();
    const [imagenQr, setImagenQr] = useState('');
    function facturarPedido() {
        fetch(`${server}/reservare/facturarPedido`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify({
                idTelefonico,"codigo_documento":conDocumento
            })
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                console.log(data);
                if (data.name == null || data.name == undefined) {
                    if (data.status == 0) {
                        var info = JSON.parse(localStorage.getItem('reservas'));
                        var act = info.filter((dt) => dt.idTelefonico == idTelefonico)[0]
                        act.pedidoNro = data.pedidoNro;
                        act.estado = 'A';
                        var time = new Date();
                        if(act.tipoPedido!=='SUCURSAL'){
                            act.tiempo = time.getTime();
                        }
                        var actualizado = info.filter((dt) => dt.idTelefonico !== idTelefonico);
                        actualizado.push(act)
                        localStorage.setItem('reservas', JSON.stringify(actualizado));
                        navigate("/misReservas", { replace: true });
                    } else if (data.status==-162){
                        var info = JSON.parse(localStorage.getItem('reservas'));
                        var act = info.filter((dt) => dt.idTelefonico == idTelefonico)[0]
                        act.pedidoNro = data.pedidoNro;
                        act.estado = 'A';
                        var time = new Date();
                        if(act.tipoPedido!=='SUCURSAL'){
                            act.tiempo = time.getTime();
                        }
                        var actualizado = info.filter((dt) => dt.idTelefonico !== idTelefonico);
                        actualizado.push(act)
                        localStorage.setItem('reservas', JSON.stringify(actualizado));
                        navigate("/misReservas", { replace: true });
                    }else {
                        if(data.mensaje!==null&&data.mensaje!=undefined){
                            setMessage("Ocurrio un problema, "+data.mensaje);
                        }else{
                            setMessage("Intenta nueva mente por favor...")
                        }
                        setAlerta(true);
                    }
                } else {
                    setMessage(data.code + " Error de conección, intente mas tarde");
                    setAlerta(true);
                }
            })
            .catch(error => msgToask.msgError(error));
    }
    function finalizarPago() {
        var idsuc = localStorage.getItem('filtroSucursal');
        fetch(`${server}/reservare/estadoQr`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify({
                ID_SUCURSAL: idsuc, ID_QR: idPago, REFRESCAR: false, BANCO: 'SCZ',regional
            })
        })
            .then(res => res.json())
            .then(data => {
                //setLoading(false);
                setEstado(data);
                if(data.autorizado=='S'){
                    facturarPedido();
                }else{
                    if (data.estado == 2) {
                        facturarPedido()
                        //navigate("/misReservas", { replace: true });
                    } else {
                        setLoading(false);
                        setShowNoti(true);
                    }
                }
            })
            .catch(error => msgToask.msgError(error));
    }
    function descargarImg() {
        if (idPago !== '') {
            saveAs(`${server}/imgQr/pago${regional}Qr${idPago}.png`, 'MiImagenQr.jpg');
        }
    }
    
    useEffect(() => {
        var info = utilsRecervas.getPedidoReserva();
        if (info !== '') {
            fetch(`${server}/reservare/miQrImagen`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify({"ciudad":info.ciudad,"idPago":info.idPago})
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    console.log(info.idPago, "codigo pago");
                    setConDocumento(info.pedido.codigo_documento);
                    setTotal(info.pedido.total);
                    setIdTelefonico(info.idTelefonico);
                    setRegional(info.ciudad);
                    setIdPago(info.idPago);
                })
                .catch(err => {
                    console.log(err);
                    setMessage(err.message + " Error de conección intente mas tarde");
                    setAlerta(true);
                });
        }
    }, [])
    return (
        <div>
            <Header pagina={6} title={"Pago QR"}></Header>
            <div className='container-fluid col-sm-12 col-md-6 col-xl-4'>
                <div className='text-center textInicioSub py-2'>
                    Por favor escaneé el codigo QR para pagar
                </div>
                <div className='text-center w-100 mb-2'>
                    {idPago !== '' && <img src={`${server}/imgQr/pago${regional}Qr${idPago}.png`} className="img-fluid" />}
                </div>
                <div className='textInicio fs-5 text-center'>
                    {`Total a Pagar: ${total} Bs`}
                </div>
                <div className='textInicio text-center'>
                    {`Id Unico: ${idTelefonico}`}
                </div>
            </div>
            <div className='floatBotones text-center w-100' style={{ bottom: '1%' }}>
                <div className='container-fluid text-center col-sm-12 col-md-6 col-xl-4'>
                    <a className='btn btn-sm botonNaranja w-100 rounded-pill text-light'
                        href={`tel:${regional == '990' ? '4200780' : '3336171'}`}>
                        <i className="fa-solid fa-phone"></i> Llamar
                    </a>
                    <div className='container-fluid text-center mb-2 textCard'>
                        {regional == '990' ? 'Numero: 4200780' : 'Numero: 3336171'}
                    </div>
                    <button className='btn btn-sm botonNaranja w-100 rounded-pill text-light mb-1' onClick={() => descargarImg()}>
                        <i className="fa-regular fa-circle-down fa-xl"></i> Descargar
                    </button>
                    <button className='btn w-100 rounded-pill text-white botonPlomo'
                        onClick={() => { setLoading(true); finalizarPago() }}>
                        Finalizar
                    </button>
                </div>
            </div>
            <Modal show={showNoti} contentClassName="bg-transparent "
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
                <Modal.Header bsPrefix="modal-header m-0 p-0">
                    <div className='verPedidoButon w-100 text-center my-auto py-1'
                        style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
                        <div className='text-white textTitle'>NOTIFICACIÓN</div>
                    </div>
                </Modal.Header>
                <Modal.Body bsPrefix="modal-body m-0 p-0 bg-light">
                    {estado &&
                        <div className='container-fluid py-2'>
                            <p className='textInfo text-center mb-1'>El pago qr se encuentra en estado</p>
                            {estado.estado === 1 && <p className='textTitle text-center fw-bold'>PENDIENTE</p>}
                            {estado.estado === 3 && <p className='textTitle text-center fw-bold'>EXPIRADO</p>}
                            {estado.estado === 4 && <p className='textTitle text-center fw-bold text-danger'>ERROR</p>}
                            {estado.estado === -1 && <p className='textTitle text-center fw-bold'>Error del sistema</p>}
                            <p className='textInfo text-center mb-1'>intente nuevamente pulsando en el boton</p>
                            <p className='textInfo text-center mb-1 fw-bold'>Finalizar</p>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer bsPrefix="modal-footer text-center m-0 p-0 mx-auto bg-light w-100">
                    <button className="btn botonPlomo text-light rounded-pill" onClick={() => { setShowNoti(false) }}>
                        Aceptar
                    </button>
                </Modal.Footer>
            </Modal>
            <MsgAlertas msg={message} show={alerta} callback={() => setAlerta(false)} />
            <PopupLoader loading={loading} />
        </div>
    )
}

export default QrPagoVista
