import { Buffer } from 'buffer';
const server = process.env.REACT_APP_SERVER;

function modificarHora(idTelefonico, horaModificada) {
    var reservas = JSON.parse(localStorage.getItem('reservas'));
    var pedido = reservas.filter((item) => item.idTelefonico == idTelefonico)[0]
    reservas = reservas.filter((item) => item.idTelefonico != idTelefonico)
    var aux = horaModificada.split(':')
    var tiempo = new Date();
    tiempo.setHours(parseInt(aux[0]), parseInt(aux[1]), 0);
    pedido.tiempo = tiempo.getTime();
    pedido.pedido.para_hora = horaModificada;
    reservas.push(pedido);
    localStorage.setItem('reservas', JSON.stringify(reservas));
}

function addRecerva(respuesta, pedido) {
    var recervas = JSON.parse(localStorage.getItem('reservas'));
    var time = new Date();
    if (localStorage.getItem('tipoPedido') == 'SUCURSAL') {
        var aux = pedido.para_hora.split(':');
        time.setHours(parseInt(aux[0]), parseInt(aux[1]), 0);
    }
    if (recervas === null) {
        var recerva = [{
            "idTelefonico": respuesta.idTelefonico,
            "tipoPedido": localStorage.getItem('tipoPedido'),
            "pedidoNro": respuesta.pedidoNro,
            "idPago": respuesta.idPago,
            "pedido": pedido,
            "tiempo": time.getTime(),
            "estado": respuesta.estado,
            "ciudad": respuesta.ciudad
        }]
        localStorage.setItem('reservas', JSON.stringify(recerva));
    } else {
        recervas.push({
            "idTelefonico": respuesta.idTelefonico,
            "tipoPedido": localStorage.getItem('tipoPedido'),
            "pedidoNro": respuesta.pedidoNro,
            "idPago": respuesta.idPago,
            "pedido": pedido,
            "tiempo": time.getTime(),
            "estado": respuesta.estado,
            "ciudad": respuesta.ciudad
        })
        localStorage.setItem('reservas', JSON.stringify(recervas));
    }
}

function cantidadRecervas() {
    var recervas = JSON.parse(localStorage.getItem('reservas'));
    if (recervas === null) {
        return 0
    } else {
        return recervas.length;
    }
}
function eliminarRecerva(idTelefonico) {
    var recervas = JSON.parse(localStorage.getItem('reservas'));
    var actualizado = recervas.filter((item) => item.idTelefonico != idTelefonico);
    localStorage.setItem('reservas', JSON.stringify(actualizado));
}
function getIpservidor(idSucursal) {
    var sucursales = JSON.parse(localStorage.getItem('sucursales'));
    var ipServer = sucursales.filter((item) => item.ID_SUCURSAL == idSucursal)[0].SERVER;
    return ipServer;
}
function getNombreSucursal(idSucursal) {
    var sucursales = JSON.parse(localStorage.getItem('sucursales'));
    var sucursal = sucursales.filter((item) => item.ID_SUCURSAL == idSucursal)[0].SUCURSAL;
    return sucursal;
}
function getHorarioSucursal(idSucursal) {
    var config = JSON.parse(localStorage.getItem('configHR')).CONFIGURACION;
    if (config != null) {
        var texto = Buffer.from(config);
        var aux = texto.toString();
        //console.log(aux)
        aux = aux.split('\r\n').filter(item => item.indexOf('L|HorarioSucursalesReenvio|') != -1)[0];
        aux = aux.split('|');
        return aux
    } else {
        return ''
    }
}
function getDemoraDelivery() {
    if (localStorage.getItem('configHR') !== undefined && localStorage.getItem('configHR') !== null) {
        var config = JSON.parse(localStorage.getItem('configHR')).CONFIGURACION;
        if (config != null && config != undefined) {
            var texto = Buffer.from(config);
            var aux = texto.toString();
            aux = aux.split('\r\n').filter(item => item.indexOf('L|DemoraAproximadaPedidos|') != -1)[0];
            aux = aux.split('|');
            return aux[2] + ' a ' + aux[3]
        } else {
            return ''
        }
    }
    return ''
}
function getListaSucursalesActivos() {
    if (localStorage.getItem('configHR') !== undefined && localStorage.getItem('configHR') !== null) {
        var config = JSON.parse(localStorage.getItem('configHR')).CONFIGURACION;
        if (config != null && config != undefined) {
            var texto = Buffer.from(config);
            var aux = texto.toString();
            console.log(aux)
            aux = aux.split('\r\n').filter(item => item.indexOf('L|SucursalesReenvio|') != -1)[0];
            aux = aux.split('|');
            return aux
        } else {
            return []
        }
    }
    return []
}
function getHorarioSucursalRegional(regional, idSucursal) {
    var config = JSON.parse(localStorage.getItem('configHR'));
    if (config != null) {
        var texto = Buffer.from(config.CONFIGURACION);
        var aux = texto.toString();
        console.log(aux);
        aux = aux.split('\r\n').filter(item => item.indexOf('L|ConfigAppCiudad|' + regional) != -1)[0];
        aux = aux.split('|');
        return aux;
    } else {
        return ''
    }
}

function getHoraInicio() {
    var info = getHorarioSucursal(9);
    var suc = localStorage.getItem('filtroSucursal')
    var horaInicio = info[info.indexOf(suc) + 1] / 60;
    var info = Math.trunc(horaInicio) + ':' + (horaInicio - Math.trunc(horaInicio)) * 60;
    return Math.trunc(horaInicio) + ':' + (horaInicio - Math.trunc(horaInicio)) * 60
}
function getPedidoReserva() {
    var info = localStorage.getItem('telefonico')
    if (info !== undefined && info !== null) {
        var reservas = JSON.parse(localStorage.getItem('reservas'));
        var info = reservas.filter((item) => item.idTelefonico == info);
        if (info.length !== 0) {
            return info[0]
        } else {
            return ''
        }
    }
    return ''
}
export default {
    addRecerva, cantidadRecervas, eliminarRecerva, getIpservidor, getPedidoReserva,
    getNombreSucursal, getHorarioSucursal, getHorarioSucursalRegional, getListaSucursalesActivos,
    getHoraInicio, getDemoraDelivery, modificarHora
}