function capitalize(word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }
function getMenuSucursal(item){
    var menu
    var list=JSON.parse(localStorage.getItem('sucursales'));
    var listaApp=JSON.parse(localStorage.getItem('menuApp')).map(item=>item.ID_MENU);
    list.map((suc)=>{
        var valor=suc.ID_SUCURSAL;
        if(item==valor){
            menu=suc.MENU
        }
    })
    return menu;
}
function getMenuCategoria(item){
    var menu
    var list=JSON.parse(localStorage.getItem('menucategorias'));
    //console.log(item)
    list.map((cat)=>{
        var valor=cat.ID_CATEGORIA;
        if(item==valor){
            menu=cat.MENU
        }
    })
    return menu;
}
function getMenuUnion(menuSuc,menuCat){
    let intersection,finalLista
    if(menuSuc.length<=menuCat.length){
        intersection = menuSuc.filter(v => menuCat.includes(v))
    }else{
        intersection = menuCat.filter(v => menuSuc.includes(v))
    }
    var listaMenu=JSON.parse(localStorage.getItem('listamenu'));
    intersection=intersection.map(Number)
    finalLista=listaMenu.filter(v => intersection.includes(v.ID));
    return finalLista
}
function geMenuUnionCategoria(menuCat){
    var listaMenu=JSON.parse(localStorage.getItem('listamenu'));
    var finalLista
    var menuFilter=menuCat.map(Number)
    finalLista=listaMenu.filter(v => menuFilter.includes(v.ID));
    return finalLista
}
function geMenuUnionSucursal(menuSuc){
    var listaMenu=JSON.parse(localStorage.getItem('listamenu'));
    var finalLista
    var menuFilter=menuSuc.map(Number)
    finalLista=listaMenu.filter(v => menuFilter.includes(v.ID));
    return finalLista
}

function getMenuSeleccionado(id){
    var listaMenu=JSON.parse(localStorage.getItem('listamenu'));
    var itemSeleccionado=listaMenu.filter(v=>v.ID===id)
    return itemSeleccionado[0]
}
function getNameSucursal(id){
    var listaSuc=JSON.parse(localStorage.getItem('sucursales'));
    if(listaSuc!=null){
        var itemSeleccionado=listaSuc.filter(v=>v.ID_SUCURSAL==id);
        console.log(itemSeleccionado);
        return itemSeleccionado[0];
    }else{
        return null;
    }
}
export default {capitalize,getMenuSucursal,getMenuCategoria,getMenuUnion,geMenuUnionCategoria,geMenuUnionSucursal,getMenuSeleccionado,getNameSucursal};