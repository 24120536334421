import React,{useState, useEffect} from 'react'
import utilsVariantes from '../utils/utilsVariantes';
import '../css/CardVariante.css';
const server = process.env.REACT_APP_SERVER
function CardGrupoVariante(props) {
    const {item,img,cantProducto,setCantProducto,grupo,idMenu,idPedido,tipo}=props
    const [cantidad,setCantidad]=useState(0)
    const [despacho,setDespacho] = useState('');
    const aumentar = (variante) => {
        var idProductoVariante=variante;
        if (cantProducto!=0) {
            var variante=JSON.parse(localStorage.getItem('variante'))
            //console.log(variante);
            if(utilsVariantes.estaVacio(variante)){
                variante.push({
                    grupo:grupo,
                    idPedido:idPedido,
                    idMenu:idMenu,
                    idProductoVariante:idProductoVariante,
                    cantidad:cantidad+1,
                    tipo_despacho:despacho
                });
                localStorage.setItem('variante',JSON.stringify(variante))
            }else{
                if(utilsVariantes.exiteVariante(variante,grupo,idProductoVariante,idPedido)){
                    utilsVariantes.actualizarVariante(variante,grupo,idProductoVariante,cantidad+1,idPedido)
                }else{
                    variante.push({
                        grupo:grupo,
                        idPedido:idPedido,
                        idMenu:idMenu,
                        idProductoVariante:idProductoVariante,
                        cantidad:cantidad+1,
                        tipo_despacho:despacho
                    });
                    localStorage.setItem('variante',JSON.stringify(variante))
                }
            }
            setCantidad(cantidad + 1)
            setCantProducto(cantProducto-1)
        }
    }
    const disminuir = (variante) => {
        var idProductoVariante=variante;
        if (cantidad > 0) {
            var variante=JSON.parse(localStorage.getItem('variante'))
            if(utilsVariantes.exiteVariante(variante,grupo,idProductoVariante,idPedido)){
                utilsVariantes.actualizarVariante(variante,grupo,idProductoVariante,cantidad-1,idPedido)
            }
            setCantidad(cantidad - 1)
            setCantProducto(cantProducto+1)
        }
    }
    useEffect(()=>{
        var listaVariantes = JSON.parse(localStorage.getItem('variante'));
        var elegido = JSON.parse(localStorage.getItem("elegido"))[0];
        setDespacho(elegido.tipo_despacho);
        if(utilsVariantes.exiteVariante(listaVariantes,grupo,item.ID_PRODUCTO_VARIANTE,idPedido)){
            var dato=utilsVariantes.getItemVariante(listaVariantes,grupo,item.ID_PRODUCTO_VARIANTE)
            setCantidad(dato)
        }
    },[grupo])
    return (
        <div className='col'>
            <div className="card cardSinBorder  mx-auto mb-2">
                <div className="card-body text-center recuadro" onClick={()=>aumentar(item.ID_PRODUCTO_VARIANTE)}>
                    <div className="fs-6 text-dark textTitle d-none">
                        {item.DESCRIPCION}
                    </div>
                    <img src={`${server}/imagenes/${img}.png`} className={`card-img-top mx-auto ${tipo==1?'imgCardVariante':'imgCardVarianteG'} `} />
                </div>
                <div className="card-footer my-auto text-center m-0 p-0">
                    <div className="row row-cols-3 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-0 botonesContenedor">
                        <div className='col text-start my-auto'>
                            <button className='btn  text-center text-white disminuir' id={item.ID_PRODUCTO_VARIANTE} onClick={()=>disminuir(item.ID_PRODUCTO_VARIANTE)}>
                                -
                            </button>
                        </div>
                        <div className='col text-center my-auto'>
                            <div className="text-white textoCenter fs-4">
                                {cantidad}
                            </div>
                        </div>
                        <div className='col text-end my-auto'>
                            <button className='btn text-center text-white aumentar' id={item.ID_PRODUCTO_VARIANTE} onClick={()=>aumentar(item.ID_PRODUCTO_VARIANTE)}>
                                +
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardGrupoVariante
