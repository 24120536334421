import React, { useState, useEffect } from 'react'
import utils from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import msg from '../utils/msgToask';
import 'react-toastify/dist/ReactToastify.css';
import utilsCarrito from '../utils/utilsCarrito';
import utilsRecervas from '../utils/utilsRecervas';
import Header from './Header';
import PopupLoader from './utils/PopupLoader';
import MsgAlertas from './utils/MsgAlertas';
const server = process.env.REACT_APP_SERVER

export default function RealizarReserva(props) {
  const { setPantalla, setCarrito, carrito, regional } = props;
  const navigate = useNavigate();
  const [nit, setNit] = useState('');
  const [nombre, setNombre] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [idDespacho, setIdDespacho] = useState(1);
  const [error, setError] = useState({})
  const [listaDespacho, setListaDespacho] = useState([]);
  const [conFactura, setConFactura] = useState(false);
  const [cliente, setCliente] = useState('');
  const [despacho, setDespacho] = useState(false);
  const [tipoPedido, setTipoPedido] = useState('');
  const [generoQr, setGeneroQr] = useState(false);
  const [horaPedido, setHoraPedido] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [hayPagoqr, setHayPagoqr] = useState(true);
  const [hayPagoe, setHayPagoe] = useState(true);

  const cancelarTodo = () => {
    localStorage.removeItem('variante');
    localStorage.removeItem('elegido');
    setPantalla(0)
  }
  async function generarCodigoQr(data) {
    console.log(data)
    var info = JSON.parse(localStorage.getItem('recervas'))[0]
    var idSuc = localStorage.getItem('filtroSucursal');
    fetch(`${server}/reservare/generarQr`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        ID_SUCURSAL: idSuc,
        DETALLE: 'Pedido' + info.pedidoNro + utilsRecervas.getNombreSucursal(idSuc),
        MONTO: info.pedido.total, BANCO: 'SCZ', AUX: data
      })
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (data.ok) {
          var inf = JSON.parse(localStorage.getItem('infUser'))
          inf.idPagoQr = data.ok;
          localStorage.setItem('infUser', JSON.stringify(inf));
          setGeneroQr(true);
          navigate("/QrPagar", { replace: true });
        } else {
          setGeneroQr(false);
          msg.msgError(data.error);
        }
      })
      .catch(error => msg.msgError(error));
  }
  async function realizarPedido(pedido, tipo) {
    console.log(pedido);
    var idSucursal = localStorage.getItem('filtroSucursal');
    //var ipServer = utilsRecervas.getIpservidor(idSucursal);
    fetch(`${server}/reservare`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        pedido, idSucursal
      })
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        console.log("respuesta", data);
        if (data.status == 0) {
          if (tipoPedido === 'DOMICILIO' || tipo == 'Q' ||tipoPedido=='FEXCO') {
            if (data.idPago !== '') {
              let datos = { ...data, "estado": 'P', "ciudad": regional }
              localStorage.setItem('telefonico', data.idTelefonico);
              utilsRecervas.addRecerva(datos, pedido);
              localStorage.removeItem('carrito');
              setGeneroQr(true);
              navigate("/QrPagar", { replace: true });
            } else {
              setGeneroQr(false);
              setMessage("Intente Nuevamento no pudimos enviar su pedido !")
              setAlerta(true);
            }
          } else {
            let datos = { ...data, "estado": 'A', "ciudad": regional }
            localStorage.setItem('telefonico', data.idTelefonico);
            utilsRecervas.addRecerva(datos, pedido);
            localStorage.removeItem('carrito');
            setLoading(false);
            navigate("/misReservas", { replace: true });
          }
        } else {
          setLoading(false);
          if (data.mensaje !== undefined && data.mensaje !== null) {
            msg.msgError(data.mensaje);
            setMessage(data.mensaje);
            setAlerta(true);
          } else {
            msg.msgError(data.message);
            setMessage(data.message)
            setAlerta(true);
          }
        }
      })
      .catch(error => {
        msg.msgError(error.message);
        setMessage(error.message);
        setAlerta(true);
      });
  }
  function validarCorreo(email) {
    if (email === '' || email.includes('@')) {
      return true
    }
    return false;
  }
  function validarOpenSucursal(suc, hora) {
    var infor = utilsRecervas.getHorarioSucursalRegional(regional, 9);
    var hro = 0, hrc = 0
    if (infor[infor.indexOf(3)] != 0) {
      hro = infor[4];
      hrc = infor[5];
    }
    var info = utilsRecervas.getHorarioSucursal(9)
    var horaIni = info[info.indexOf(suc) + 1];
    var horaFin = info[info.indexOf(suc) + 2];
    var dayminutes = (parseInt(hora[0]) * 60) + parseInt(hora[1]);
    console.log(dayminutes, hro, hrc);
    if ((dayminutes >= horaIni && (hro == 0 || dayminutes >= hro)) && (dayminutes < horaFin && (hrc == 0 || dayminutes < hrc))) {
      return true;
    } else {
      return false;
    }
  }
  function validarHora(hora) {
    if (tipoPedido === 'DOMICILIO') {
      return true;
    } else {
      var horas = hora.split(':');
      var day = new Date()
      if (parseInt(horas[0]) >= day.getHours()) {
        if ((parseInt(horas[0]) * 60) + parseInt(horas[1]) >= (day.getHours() * 60) + day.getMinutes() + 30) {
          return validarOpenSucursal(localStorage.getItem('filtroSucursal'), hora.split(':'))
        } else {
          setMessage("Hora incorrecta, por lo menos tiene que tener 30 minutos de diferencia de la hora actual, gracias !");
          return false
        }
      } else {
        setMessage("Ya son las " + day.getHours() + " Hrs, selecciones la hora igual o mayor gracias.");
        return false;
      }
    }
  }
  const enviarPedido = async (dato) => {
    if (nit !== '' && nombre !== '' && nit !== '0' && nit !== '00' && nit !== '000' && nit !== '0000') {
      setError({});
      var infUser = JSON.parse(localStorage.getItem('infUser'))
      if (infUser.correo == '' || tipoPedido === 'SUCURSAL') {
        infUser.correo = document.getElementById('correoCliente').value;
      }
      if (tipoPedido === 'DOMICILIO'||tipoPedido=='FEXCO') {
        infUser.correo = document.getElementById('correoCliente').value;
      }
      if (validarCorreo(infUser.correo)) {
        setLoading(true);
        utilsCarrito.getTotalDetalleCompra()
        var detalles = [];
        for (var item of utilsCarrito.getCarritoDetalle()) {
          delete item.tipo_despacho;
          delete item.precioT;
          detalles.push(item);
        }
        var variantes = []
        for (var item of utilsCarrito.getCarritoVariantes()) {
          delete item.tipo_despacho;
          variantes.push(item);
        }
        var miPedido = {
          idTelefonico: 0,
          nit: nit,
          nombre: nombre,
          total: utilsCarrito.getTotalDetalleCompra(),
          tipoPago: tipoPedido === 'DOMICILIO' ? 'Q' : dato,
          tipoPedido: tipoPedido === 'DOMICILIO'?'K' : tipoPedido=='FEXCO'?'F':'R',
          recogera: tipoPedido === 'DOMICILIO' ? cliente : infUser.cliente,
          observaciones: (tipoPedido === 'DOMICILIO'||tipoPedido=='FEXCO') ? observaciones : infUser.observaciones,
          para_hora: (tipoPedido === 'DOMICILIO'||tipoPedido=='FEXCO') ? horaPedido : infUser.horaPedido,
          codigo_documento: conFactura ? 4 : 1,
          detalles: detalles,
          variantes: variantes, ...infUser
        };
        if (tipoPedido === 'DOMICILIO') {
          delete miPedido.para_hora;
        } else if(tipoPedido=='FEXCO'){
          delete miPedido.para_hora;
          miPedido.recogera="";
          miPedido.idSucursalDestino = localStorage.getItem('filtroSucursal');
          miPedido.sucursal = utilsRecervas.getNombreSucursal(miPedido.idSucursalDestino);
          miPedido.servidorip = utilsRecervas.getIpservidor(miPedido.idSucursalDestino);
        }else {
          miPedido.idSucursalDestino = localStorage.getItem('filtroSucursal');
          miPedido.sucursal = utilsRecervas.getNombreSucursal(miPedido.idSucursalDestino);
          miPedido.servidorip = utilsRecervas.getIpservidor(miPedido.idSucursalDestino);
        }
        console.log(miPedido)
        //setLoading(false);
        await realizarPedido(miPedido, dato);
      } else {
        setMessage("Ingresar un correo valido por favor ... ")
        setAlerta(true);
      }
    } else {
      if (nit === '') {
        setMessage("Ingrese NIT o CI, para la factura");
        setAlerta(true);
        setError({ noNit: "Ingrese el NIT o CI, para la recerva !!!" })
      } else if (nit !== '0' || nit !== '00' || nit !== '000' || nit !== '0000') {
        setMessage("Ingrese NIT o CI, para la factura");
        setAlerta(true);
        setError({ noNit: "Ingrese el NIT o CI, o ponga sin nit !!!" })
      } else if (nombre === '') {
        setMessage("Introduzca su nombre para la facturación ");
        setAlerta(true);
        setError({ noNombre: "Ingrese el Nombre por favor !!!" })
      } else if (cliente === '') {
        setMessage("Quien Recojera EL PEDIDO?, INGRESE EL NOMBRE??")
        setAlerta(true);
        setError({ noCliente: "Ingrese el Nombre por favor !!!" })
      }
    }
  }
  const datoFactura = (e) => {
    console.log(e.target.value);
    if (conFactura === false) {
      setConFactura(true);
      setNit('100001');
      setNombre('CLIENTE PARTICULAR');
      e.target.src = `${server}/imagenes/nombre.png`;
      document.getElementById('btnSN').innerHTML = 'Colocar Nombre';
      setMessage("Cuando pone SIN NOMBRE, no llegara la factura a su correo, considere eso por favor ...")
      setAlerta(true);
    } else {
      setConFactura(false);
      setNit('');
      setNombre('');
      e.target.src = `${server}/imagenes/sinNombre.png`
      document.getElementById('btnSN').innerHTML = 'Sin Nombre'
    }
  }
  const agregarACarrito = () => {
    cancelarTodo()
  }
  const nitIngresando = () => {
    var info = utils.getNameSucursal(localStorage.getItem("filtroSucursal"));
    if (nit.length != 0) {
      fetch(`${server}/servidorSucursal/buscarDocumento`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ info, nit })
      })
        .then(res => res.json())
        .then(data => {
          if (data.ok) {
            setNombre(data.ok.RAZON_SOCIAL);
            document.getElementById('correoCliente').value = data.ok.CORREO;
          } else {
            msg.msgError(data.error)
          }
        })
        .catch(err => {
          console.log(err);
          msg.msgError(err.message)
        });
    } else {
      msg.msgError("ingrese NIT o CI !!!")
    }
  }
  function getNumDespachos() {
    var info = utils.getNameSucursal(localStorage.getItem("filtroSucursal"));
    fetch(`${server}/servidorSucursal/infoDespachoSucursal`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ info })
    })
      .then(res => res.json())
      .then(data => {
        if (data.ok) {
          setListaDespacho(data.ok);
        } else {
          msg.msgError(data.error);
        }
      })
      .catch(err => {
        console.log(err);
        msg.msgError(err.message);
      });
  }
  function callbackBack() {
    if (tipoPedido === 'DOMICILIO'||tipoPedido =='FEXCO') {
      navigate("/miPedido", { replace: true });
    } else if (tipoPedido === 'SUCURSAL') {
      navigate("/datoAdicional", { replace: true });
    }
  }
  useEffect(() => {
    var t = localStorage.getItem('tipoPedido');
    if (t != undefined) {
      setTipoPedido(t);
    }
    var hro = utilsRecervas.getHorarioSucursalRegional(regional, 9);
    if (hro[10].indexOf('E') != -1) {
      setHayPagoe(true);
    } else {
      setHayPagoe(false);
    }
    if (hro[10].indexOf('Q') != -1) {
      setHayPagoqr(true);
    } else {
      setHayPagoqr(false);
    }
    //getNumDespachos();
  }, []);
  return (
    <div className='fondoColor vh-100'>
      <Header pagina={5} title={"Mi Pedido"} callback={() => callbackBack()}></Header>
      <div className='container-fluid col-sm-12 col-md-6 col-xl-4 py-2 overflow-auto configOverFlow'>
        <div className='text-center textTitle'>
          <b>Muchas gracias por su pedido <i className="bi bi-emoji-laughing-fill"></i></b>
        </div>
        <div className='text-center textCard'>
          Datos para Facturar
        </div>
        <div className='container-fluid text-dark py-2'>
          <div className='row row-cols-2 g-2'>
            <div className='col col-8'>
              <div className="input-group">
                <input type="number"
                  className="form-control textInfo"
                  value={nit}
                  disabled={conFactura}
                  onChange={(e) => setNit(e.target.value)}
                  placeholder='NIT o CI' />
                <button className="btn text-light botonNaranja" type="button" onClick={nitIngresando} disabled={conFactura}>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
              {error.noNit && <span className='badge bg-danger text-wrap'>{error.noNit}</span>}
              <div className='py-2'>
                <input type="text"
                  className="form-control textInfo"
                  value={nombre}
                  disabled={conFactura}
                  onChange={(e) => { setNombre(e.target.value) }}
                  placeholder='Nombre de Factura' />
                {error.noNombre && <span className='badge bg-danger text-wrap'>{error.noNombre}</span>}
              </div>
            </div>
            <div className='col col-4 my-auto text-center'>
              <button className='btn btn botonPlomo text-light ' id='btnSN' onClick={datoFactura}
                style={{ width: '78px', height: '78px', borderRadius: '17px' }}>
                Sin Nombre
              </button>
              <div className="mb-2 d-none">
                <img src={`${server}/imagenes/sinNombre.png `} className='d-none' id="sinNombre" onClick={datoFactura} width="90" height="90"></img>
              </div>
            </div>
          </div>
        </div>
        {tipoPedido === 'DOMICILIO' && <div className="container">
          <input type={tipoPedido === 'DOMICILIO' ? 'text' : "text"}
            className="form-control textInfo"
            value={cliente}
            onChange={(e) => { setCliente(e.target.value) }}
            placeholder={tipoPedido === 'DOMICILIO' ? 'Preguntar Por ?' : 'Nombre del Cliente'} />
          {error.noCliente && <span className='badge bg-danger text-wrap'>{error.noCliente}</span>}
        </div>}
        {(tipoPedido === 'DOMICILIO'||tipoPedido=='FEXCO') &&
          <div className='container-fluid mt-2'>
            <textarea type="text"
              value={observaciones}
              className="form-control textInfo mb-2 borderEdit"
              placeholder='Ingrese alguna nota para el pedido'
              onChange={(e) => setObservaciones(e.target.value.toLowerCase())}
              style={{ height: '90px' }} />
          </div>}

        <div className='container text-center'>
          <div className='text-center textInicio'>
            Proporcione su Correo Para enviarle la factura (Opcional)
          </div>
          <input type="email"
            id='correoCliente'
            className="form-control textInfo mb-1 borderEdit"
            placeholder='ejemplo@gmail.com' />
        </div>
      </div>
      <div className='container-fluid floatBotones text-center' style={{ bottom: '4px' }}>
        <div className='container-fluid col-sm-12 col-md-6 col-xl-4 '>
          {generoQr === false && tipoPedido === 'DOMICILIO' &&
            <button className='rounded-pill w-100 btn text-white  botonNaranja '
              title='Siquiente Paso'
              onClick={() => enviarPedido('P')}>
              Confirmar <i className="bi bi-send-plus-fill"></i>
            </button>}
          {generoQr && tipoPedido === 'DOMICILIO' && <button className='rounded-pill btn text-white botones'
            onClick={() => generarCodigoQr()}>
            Reintentar <i className="bi bi-send-plus-fill"></i>
          </button>}
          {tipoPedido == 'SUCURSAL' && hayPagoe && <button className='btn btn-sm w-100 rounded-pill text-white botonPlomo'
            onClick={() => enviarPedido('E')}>
            Solo Reservar
          </button>}
          {tipoPedido == 'SUCURSAL' && hayPagoe && <div className='textCard text-center mb-4'>
            Solo se tomará previsiones para su pedido
          </div>}
          {tipoPedido == 'SUCURSAL' && hayPagoqr && <button className='btn btn-sm w-100 rounded-pill text-white botonNaranja'
            onClick={() => enviarPedido('Q')} >
            Pagar QR
          </button>}
          {tipoPedido == 'SUCURSAL' && hayPagoqr && <div className='textCard text-center mb-2'>
            Sú Pedido estará listo a la hora que índica
          </div>}
          {tipoPedido == 'FEXCO' && hayPagoqr && <button className='btn btn-sm w-100 rounded-pill text-white botonNaranja'
            onClick={() => enviarPedido('Q')} >
            Pagar QR
          </button>}
        </div>
      </div>
      <MsgAlertas msg={message} show={alerta} callback={() => setAlerta(false)} />
      <PopupLoader loading={loading} />
    </div>
  )
}
