import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import msgToask from '../utils/msgToask';
import utilsRecervas from '../utils/utilsRecervas';
import Header from './Header'
import MsgAlertas from './utils/MsgAlertas';
const server = process.env.REACT_APP_SERVER
function DatoAdicional(props) {
    const { regional } = props;
    const navigate = useNavigate()
    const [message, setMessage] = useState('');
    const [alerta, setAlerta] = useState(false);
    const [horaPedido, setHoraPedido] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [telefono, setTelefono] = useState('');
    const [cliente, setCliente] = useState('');
    function validarOpenSucursal(suc, hora) {
        var infor = utilsRecervas.getHorarioSucursalRegional(regional, 9);
        var hro = 0, hrc = 0
        if (infor[infor.indexOf(3)] != 0) {
            hro = infor[4];
            hrc = infor[5];
        }
        var info = utilsRecervas.getHorarioSucursal(9)
        var horaIni = info[info.indexOf(suc) + 1];
        var horaFin = info[info.indexOf(suc) + 2];
        var dayminutes = (parseInt(hora[0]) * 60) + parseInt(hora[1]);
        console.log(dayminutes, hro, hrc);
        if ((dayminutes >= horaIni && (hro == 0 || dayminutes >= hro)) && (dayminutes < horaFin && (hrc == 0 || dayminutes < hrc))) {
            return true;
        } else {
            console.log("hora no valida");
            let scu=localStorage.getItem('filtroSucursal')
            var sucursalname=utilsRecervas.getNombreSucursal(scu);
            var info = utilsRecervas.getHorarioSucursal(scu)
            var horaIni=Math.trunc(info[info.indexOf(scu)+1]/60);
            var horaFin=Math.trunc(info[info.indexOf(scu)+2]/60);
            setMessage("El pedido para la sucursal "+sucursalname+" es permitido entre "+horaIni+' - '+horaFin+' Horas.')
            return false;
        }
    }
    function validarHora(hora) {
        var horas = hora.split(':');
        var day = new Date()
        if (parseInt(horas[0]) >= day.getHours()) {
            if ((parseInt(horas[0]) * 60) + parseInt(horas[1]) >= (day.getHours() * 60) + day.getMinutes() + 20) {
                return validarOpenSucursal(localStorage.getItem('filtroSucursal'), hora.split(':'))
            } else {
                setMessage("Hora incorrecta, por lo menos tiene que tener 30 minutos de diferencia de la hora actual, gracias !");
                return false
            }
        } else {
            setMessage("Ya son las " + day.getHours() + " Hrs, selecciones la hora igual o mayor gracias.");
            return false;
        }
    }
    function siquientePaso() {
        if (telefono !== '') {
            if ((telefono[0] === '7' || telefono[0] === '6') && telefono.length === 8) {
                if (cliente !== '') {
                    if (validarHora(horaPedido)) {
                        localStorage.setItem('infUser', JSON.stringify({ telefono, 'recogera': cliente, observaciones, 'para_hora': horaPedido }));
                        navigate("/reservar", { replace: true });
                    } else {
                        setAlerta(true);
                    }
                } else {
                    setMessage('Ingrese EL nombre del que recogera el pedido.');
                    setAlerta(true);
                }
            } else {
                setMessage('Ingresar un número de celular valido .!');
                setAlerta(true);
            }
        } else {
            setMessage('Ingresa su número de celular, por favor .!');
            setAlerta(true);
        }
    }
    useEffect(() => {
        var aux = new Date()//aux.toTimeString().split(' ')[0]
        var hora = aux.getHours()<10?'0'.concat(aux.getHours()):aux.getHours();
        var minutes = aux.getMinutes()<10?'0'.concat(aux.getMinutes()):aux.getMinutes();
        setHoraPedido(hora+':'+minutes);
        var datosU = JSON.parse(localStorage.getItem('infUser'));
        if (datosU !== null && datosU !== undefined) {
            setCliente(datosU.recogera);
            setObservaciones(datosU.observaciones);
            setTelefono(datosU.telefono);
            setHoraPedido(datosU.para_hora);
        }
    }, [])
    return (
        <div className='fondoColor w-100 vh-100'>
            <Header pagina={5} title={"Mi Pedido"} callback={() => { navigate("/miPedido", { replace: true }); }} />
            <div className='container-fluid col-sm-12 col-md-6 col-xl-4'>
                <div className='mx-auto'>
                    <div className='text-center textInicio py-2 fs-bold'>
                        Muchas gracias por su Pedido
                    </div>
                    <div className='text-center textInfo mb-4'>
                        Por favor dejanos un número de celular para notificarle.
                    </div>
                </div>
                <input type="number"
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                    className="form-control textInfo mb-2 borderEdit"
                    placeholder='Número de Celular' />
                <div className='text-center textInicio'>
                    Nombre del CLIENTE
                </div>
                <input type="text"
                    value={cliente}
                    onChange={(e) => setCliente(e.target.value)}
                    className="form-control textInfo mb-2 borderEdit"
                    maxLength={30}
                    placeholder='Nombre cliente ...' />
                <textarea type="text"
                    value={observaciones}
                    className="form-control textInfo mb-2 borderEdit"
                    placeholder='Ingrese alguna nota para el pedido'
                    onChange={(e) => setObservaciones(e.target.value.toLowerCase())}
                    style={{ height: '90px' }} />
                <div className='text-center textCard'>
                    ¿Para que hora quiere su Pedido?
                </div>
                <input type="time" style={{ width: '120px', alignItems: 'right' }}
                    value={horaPedido}
                    className="form-control textInfo text-center mx-auto"
                    id='hora'
                     onChange={(e) => setHoraPedido(e.target.value)} />
                <div className='text-center my-auto textCard'>
                    Horas
                </div>
            </div>
            <div className='container-fluid col-sm-12 col-md-6 col-xl-4 fixed-bottom mb-1'>
                <div className='container-fluid rounded-pill text-center m-0 p-0'>
                    <button className='btn botonNaranja rounded-pill text-white w-100'
                        onClick={() => siquientePaso()}>
                        Confirmar <i className="fa-solid fa-circle-arrow-right"></i>
                    </button>
                </div>
            </div>
            <MsgAlertas msg={message} show={alerta} callback={() => setAlerta(false)} />
        </div>
    )
}

export default DatoAdicional
