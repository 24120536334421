import React from 'react'
import Header from './Header';
import MapaPrueba from './MapaPrueba';

function PrincipalFexoPedido(props) {
    const { regional } = props;
    return (
        <div className='fondoConfig vh-100'>
            <Header pagina={3} />
            <div className='container'>
                <div className='textoCenter text-secondary mx-auto w-90'>
                    Coloca su posición dentro la FEXCO
                </div>
            </div>
            <div className='container-fluid'>
                <MapaPrueba regional={regional}></MapaPrueba>
            </div>
            <div className='container-fluid py-2'>
                <button className='btn w-100 rounded-pill text-white botonPlomo'
                    onClick={() => console.log("hola ")}>
                    Pedido para Fexco
                </button>
            </div>
        </div>
    )
}

export default PrincipalFexoPedido