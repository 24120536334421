import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import utils from '../utils/utils';
import utilsCarrito from '../utils/utilsCarrito';
import '../css/Cantidad.css'
import msgToask from '../utils/msgToask';
import descripcionProductos from '../utils/descripcionProductos';
import imgLlevar from '../assets/llevar.png';
import imgComer from '../assets/comer.png';
import MsgAlertas from './utils/MsgAlertas';
const server = process.env.REACT_APP_SERVER

function CantidadProducto(props) {
    const { setCarrito, carrito, setPantalla } = props;
    const navigate = useNavigate();
    const [costo, setCosto] = useState(0);
    const [descripcion, setDescripcion] = useState(0);
    const [nameImgInit, setNameImgInit] = useState('');
    const [cantidad, setCantidad] = useState(0);
    const [idMenu, setIdmenu] = useState(0);
    const [costoUni, setCostoUni] = useState(0);
    const [presas, setPresas] = useState('');
    const [acompanamiento, setAcompanamiento] = useState('');
    const [refresco, setRefresco] = useState('');
    const [salsa, setSalsa] = useState('');
    const [llajua, setLlajua] = useState('');
    const [imgDiff, setImgDiff] = useState(false);
    const [despacho, setDespacho] = useState('A');
    const [llevar, setLlevar] = useState(false);
    const [esHelado, setEsHelado] = useState(false);
    const [tipoPedido, setTipoPedido] = useState('');
    const [alerta, setAlerta] = useState(false);
    const [message,setMessage] = useState('');
    const aumentar = () => {
        var valor = utils.getMenuSeleccionado(idMenu)
        if (cantidad < 100) {
            setCosto((cantidad + 1) * valor.PRECIO)
            setCantidad(cantidad + 1)
        }
    }
    const disminuir = () => {
        var valor = utils.getMenuSeleccionado(idMenu)
        if (cantidad > 0) {
            if (cantidad > 1) {
                setCosto((cantidad - 1) * valor.PRECIO)
            }
            setCantidad(cantidad - 1)
        }
    }
    const irVariantes = () => {
        if (cantidad !== 0) {
            var fecha = new Date();
            const productor = []
            var filtro = localStorage.getItem('filtroCategoria');
            var despa = despacho;
            if (filtro == '-102') {
                despa = 'H';
            }
            productor.push({
                idPedido: fecha.getTime(),
                idMenu: idMenu,
                cantidad: cantidad,
                precio: costoUni,
                precioT: costo,
                tipo_despacho: despa
            })
            localStorage.setItem('elegido', JSON.stringify(productor))
            var dato = utils.getMenuSeleccionado(parseInt(idMenu))
            if (dato.rows.length === 0) {
                localStorage.setItem('variante', JSON.stringify([]));
                setCarrito(carrito + 1);
                utilsCarrito.agregarACarrito();
                navigate("/miPedido", { replace: true });
            } else {
                setPantalla(2);
            }
        } else {
            setMessage("Ingresar una cantidad del Producto !")
            setAlerta(true);
        }
    }
    useEffect(() => {
        console.log("cantidad de productos");
        var categoria = localStorage.getItem("filtroCategoria");
        var tp = localStorage.getItem("tipoPedido");
        if (categoria !== '-102') {
            if (tp === 'DOMICILIO') {
                setTipoPedido('D');
                setLlevar(true);
                setDespacho('L');
            } else {
                setLlevar(true);
                setDespacho('L');
            }
            setEsHelado(false);
        } else {
            setEsHelado(true);
        }
        try {
            var elegido = localStorage.getItem('elegido')
            if (typeof (elegido) === 'string') {
                setIdmenu(parseInt(elegido))
                var dato = utils.getMenuSeleccionado(parseInt(localStorage.getItem('elegido')))
                setNameImgInit(dato.ID + (dato.DESCRIPCION).replace(/ /g, "").replace('.',''));
                setDescripcion(dato.DESCRIPCION)
                setCosto(dato.PRECIO)
                setCostoUni(dato.PRECIO)
                if (descripcionProductos[elegido] !== undefined) {
                    setImgDiff(true);
                    setPresas(descripcionProductos[elegido].presas)
                    setAcompanamiento(descripcionProductos[elegido].acompañamiento)
                    setSalsa(descripcionProductos[elegido].salsa)
                    setRefresco(descripcionProductos[elegido].refresco)
                    setLlajua(descripcionProductos[elegido].llajua)
                } else {
                    setImgDiff(false);
                }
            }else if(elegido==null||elegido==undefined){
                setPantalla(0);
            }
        } catch (error) {
            try {
                var elegido = JSON.parse(localStorage.getItem('elegido'))
                var dato = utils.getMenuSeleccionado(parseInt(elegido[0].idMenu))
                setNameImgInit(dato.ID + (dato.DESCRIPCION).replace(/ /g, ""));
                setDescripcion(dato.DESCRIPCION)
                setIdmenu(elegido[0].idMenu)
                setCantidad(elegido[0].cantidad)
                setCosto(elegido[0].precioT)
                setCostoUni(elegido[0].precio)
                setDespacho(elegido[0].tipo_despacho)
                if (elegido[0].tipo_despacho == 'A') {
                    setLlevar(false);
                } else {
                    setLlevar(true);
                }
                if (descripcionProductos[elegido[0].idMenu] !== undefined) {
                    setImgDiff(true);
                    setPresas(descripcionProductos[elegido[0].idMenu].presas)
                    setAcompanamiento(descripcionProductos[elegido[0].idMenu].acompañamiento)
                    setSalsa(descripcionProductos[elegido[0].idMenu].salsa)
                    setRefresco(descripcionProductos[elegido[0].idMenu].refresco)
                    setLlajua(descripcionProductos[elegido[0].idMenu].llajua)
                } else {
                    setImgDiff(false);
                }
            } catch (error) {
                setPantalla(0);
            }
        }
        //console.log(descripcionProductos[elegido])
        /**/
    }, []);
    return (
        <div className='container-fluid col-sm-12 col-md-6 col-xl-4'>
            <div className='overflow-auto configOverFlow container py-4'>
                <div className='container-fluid text-center' onClick={()=>aumentar()}>
                    <img src={imgDiff ? `${server}/imagenes/c${nameImgInit}.png` : `${server}/imagenes/${nameImgInit}.png`} className="card-img-top mx-auto imagenMenu" />
                </div>
                <div className='container text-center textCard'>
                    <div className="badge text-uppercase text-wrap fs-6 text-dark textTitle">
                        {descripcion}
                    </div>
                    {presas !== '' && <div>
                        {presas}
                    </div>}
                    {acompanamiento !== '' && <div>
                        {acompanamiento}
                    </div>}
                    {refresco !== '' && <div>
                        {refresco}
                    </div>}
                    {salsa !== '' && <div>
                        {salsa}
                    </div>}
                    {llajua !== '' && <div>
                        {llajua}
                    </div>}
                </div>
                <div className='container-fluid py-2'>
                    <div className="row row-cols-3 row-cols-sm-3 botonesContenedor2">
                        <div className='col text-start'>
                            <button className='btn text-center text-white textButtonL' onClick={disminuir}>
                                -
                            </button>
                        </div>
                        <div className='col text-center py-2'>
                            <div className="text-white textCenterC">
                                {cantidad}
                            </div>
                        </div>
                        <div className='col text-end'>
                            <button className='btn text-center text-white textButtonR' onClick={aumentar}>
                                +
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container text-center">
                    <div className='container'>
                        <span className='textInicio fs-1'>{costo}<sup className='textInicio'>Bs</sup></span>
                    </div>
                    {false &&
                        <div className={esHelado ? "d-none" : "btn-group"} role="group" aria-label="Basic radio toggle button group">
                            <input type="radio"
                                className="btn-check"
                                name="btnradio"
                                id="btnradio1"
                                autocomplete="off"
                                checked={!llevar} onChange={() => { setLlevar(false); setDespacho('A') }} />
                            <label className="btn btn-outline-secondary" for="btnradio1"><img src={imgComer}></img> Aqui</label>
                            <input type="radio"
                                className="btn-check"
                                name="btnradio"
                                id="btnradio2"
                                autocomplete="off"
                                checked={llevar} onChange={() => { setLlevar(true); setDespacho('L') }} />
                            <label className="btn btn-outline-secondary" for="btnradio2">Llevar <img src={imgLlevar}></img></label>
                        </div>}
                </div>
            </div>
            <div className="text-center floatBotones d-none" style={{ top: '2%', left: '5%' }} onClick={() => { setPantalla(0) }}>
                <img src={`${server}/imagenes/atras.png`} width="60" height="60"></img>
            </div>
            <div className='container-fluid col-sm-12 col-md-6 col-xl-4 fixed-bottom py-1'>
                <div className='container-fluid text-center m-0 p-0'>
                    <button className='btn btn-sm text-white fw-bold w-100 botonNaranja rounded-pill'
                        to="/miPedido"
                        onClick={() => irVariantes()}>
                        Siguiente <i className="bi bi-caret-right-fill"></i>
                    </button>
                </div>
            </div>
            <MsgAlertas msg={message} show={alerta} callback={() => setAlerta(false)} />
        </div>
    )
}

export default CantidadProducto
