function exiteVariante(listaVariante,grupo,id,idPedido){
    for (const item of listaVariante){
        if(item.grupo==grupo && item.idProductoVariante==id && item.idPedido==idPedido){
            return true
        }
    }
    return false
}
function estaVacio(listaVariante){
    if(listaVariante.length===0){
        return true
    }else{
        return false
    }
}
function actualizarVariante(listaVariante,grupo,id,cantidad,idPedido){
    for (const item of listaVariante){
        if(item.grupo==grupo && item.idProductoVariante==id && item.idPedido==idPedido){       
            item.cantidad=cantidad;   
        }
    }
    localStorage.setItem('variante',JSON.stringify(listaVariante));
}
function getVariantesSucursalActivos(){
    var suc=localStorage.getItem('filtroSucursal');
    var sucursales = JSON.parse(localStorage.getItem('sucursales')).filter((item)=>item.ID_SUCURSAL==suc)[0].VARIANTES;
    return sucursales.split(':')
}
function getItemVariante(listaVariante,grupo,id){
    for (const item of listaVariante){
        if(item.grupo==grupo && item.idProductoVariante==id){
            return item.cantidad
        }
    }
}
function exiteGrupo(grupo){
    var listaVariates=JSON.parse(localStorage.getItem('variante'));
    var sumaCantidad=0;
    for(const item of listaVariates){
        if(item.grupo==grupo && item.cantidad!=0){
            sumaCantidad=sumaCantidad+parseInt(item.cantidad);
        }
    }
    if(sumaCantidad!==0){
        return true
    }else{
        return false
    }
}
function setCantidad(grupo){
    var listaVariates=JSON.parse(localStorage.getItem('variante'));
    var sumaCantidad=0;
    for(const item of listaVariates){
        if(item.grupo==grupo && item.cantidad!=0){
            sumaCantidad=sumaCantidad+parseInt(item.cantidad);
        }
    }
    return parseInt(sumaCantidad);
}
function sinCantidadCeros(lista){
    var filtrado=[]
    for(const item of lista){
        if(item.cantidad!=0){
            filtrado.push(item)
        }
    }
    return JSON.stringify(filtrado)
}
export default {exiteVariante,estaVacio,actualizarVariante,getItemVariante,exiteGrupo,setCantidad,sinCantidadCeros,getVariantesSucursalActivos}