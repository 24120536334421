import React, { useEffect, useState } from 'react'
import Header from './Header';
import Modal from 'react-bootstrap/Modal';
import MsgAlertas from './utils/MsgAlertas';
import { useNavigate } from 'react-router-dom';
import utilsRecervas from '../utils/utilsRecervas';
import CardReserva from './CardReserva';
import PopupLoader from './utils/PopupLoader';
const server = process.env.REACT_APP_SERVER

function RecervasRealizadas(props) {
    const { setPantalla, setCarrito, carrito } = props;
    const navigate = useNavigate();
    const [listaRecervas, setListaRecervas] = useState([]);
    const [actualizar, setActualizar] = useState(false);
    const [showNoti, setShowNoti] = useState(false)
    const [selectItem, setSelectItem] = useState({});
    const [showCambioModal, setShowCambioModal] = useState(false);
    const [modificarHora, setModificarHora] = useState('');
    const [showAlerta,setShowAlerta] = useState(false);
    const [mensageA,setMensageA] = useState('');
    const [loading,setLoading] = useState(false);
    const [cambioHora,setCambioHora] = useState(false);
    const salirRecervas = () => {
        navigate("/", { replace: true });
    }
    const recogiPedido = () => {
        localStorage.removeItem('reservas');
        //setCarrito(carrito - 1);
        salirRecervas();
    }
    function validarOpenSucursal(suc, hora,regional) {
        var infor = utilsRecervas.getHorarioSucursalRegional(regional, 9);
        var hro = 0, hrc = 0
        if (infor[infor.indexOf(3)] != 0) {
            hro = infor[4];
            hrc = infor[5];
        }
        var info = utilsRecervas.getHorarioSucursal(9)
        var horaIni = info[info.indexOf(suc) + 1];
        var horaFin = info[info.indexOf(suc) + 2];
        var dayminutes = (parseInt(hora[0]) * 60) + parseInt(hora[1]);
        console.log(dayminutes, hro, hrc);
        if ((dayminutes >= horaIni && (hro == 0 || dayminutes >= hro)) && (dayminutes < horaFin && (hrc == 0 || dayminutes < hrc))) {
            return true;
        } else {
            console.log("hora no valida");
            var sucursalname=utilsRecervas.getNombreSucursal(suc);
            var info = utilsRecervas.getHorarioSucursal(suc)
            var horaIni=Math.trunc(info[info.indexOf(suc)+1]/60);
            var horaFin=Math.trunc(info[info.indexOf(suc)+2]/60);
            setMensageA("El pedido para la sucursal "+sucursalname+" es permitido entre "+horaIni+' - '+horaFin+' Horas.')
            return false;
        }
    }
    function validarHora(hora){
        var horas = hora.split(':');
        var day = new Date()
        console.log(selectItem)
        var horaAnterior= selectItem.pedido.para_hora.split(":")
        if (parseInt(horas[0]) >= day.getHours()) {
            if((parseInt(horaAnterior[0]) * 60) + parseInt(horaAnterior[1])<(day.getHours() * 60) + day.getMinutes() - 25){
                if ((parseInt(horas[0]) * 60) + parseInt(horas[1]) >= (day.getHours() * 60) + day.getMinutes() + 30) {
                    return validarOpenSucursal(selectItem.pedido.idSucursalDestino,hora.split(':'),selectItem.ciudad)
                } else {
                    setMensageA("Hora incorrecta, por lo menos tiene que tener 30 minutos de diferencia de la hora actual, gracias !");
                    return false
                }
            }else{
                setMensageA("No podemos cambiar la hora, su pedido ya esta siendo preparado");
                return false
            }
        } else {
            setMensageA("Ya son las " + day.getHours() + " Hrs,Elija una hora valida actual +30 minutos por favor...");
            return false;
        }
    }
    function guardarCambioHora() {
        if (selectItem && validarHora(modificarHora)) {
            setShowCambioModal(false);
            setLoading(true);
            if (selectItem.tipoPedido === 'SUCURSAL' && selectItem.pedido.tipoPago === 'E') {
                console.log("cambiar hora de la recerva")
                fetch(`${server}/reservare/modificarHoraReserva`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 'idTelefonico':selectItem.idTelefonico, modificarHora })
                })
                    .then(res => res.json())
                    .then(data => {
                        setLoading(false);
                        if (data.ok) {
                            utilsRecervas.modificarHora(selectItem.idTelefonico,modificarHora);
                            setMensageA(data.ok);
                            setShowAlerta(true);
                            setCambioHora(true);
                        } else {
                            setMensageA(data.error);
                            setShowAlerta(true);
                            setCambioHora(false);
                        }
                    })
                    .catch(err => {
                        setCambioHora(false);
                        setMensageA(err.message);
                        setShowAlerta(true);
                    });
            } else {
                console.log("modificar hora de la reserva pagada")
                fetch(`${server}/reservare/modificarHoraReservaPagada`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                        'idTelefonico':selectItem.idTelefonico, 
                        modificarHora,
                        "servidorIp":selectItem.pedido.servidorip,
                        "sucursal":selectItem.pedido.idSucursalDestino,
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        setLoading(false);
                        if (data.status==0) {
                            utilsRecervas.modificarHora(selectItem.idTelefonico,modificarHora);
                            setMensageA(data.mensaje);
                            setCambioHora(true);
                            setShowAlerta(true);
                        } else {
                            setCambioHora(false);
                            setMensageA(data.mensaje);
                            setShowAlerta(true);
                        }
                    })
                    .catch(err => {
                        setCambioHora(false);
                        setMensageA(err.message);
                        setShowAlerta(true);
                    });
            }
        }else{
            setCambioHora(false);
            setShowCambioModal(false);
            setShowAlerta(true);
        }
    }
    function salirMensaje(){
        setShowAlerta(false);
        if(cambioHora===true){
            setCambioHora(false);
            setActualizar(!actualizar)
        }
    }
    useEffect(() => {
        var dato = JSON.parse(localStorage.getItem('reservas'));
        if(dato.length!==0){
            dato.sort((a, b) => a.tiempo <= b.tiempo ? 1 : -1);
            setListaRecervas(dato);
            if (dato[0].estado == 'A') {
                setShowNoti(true);
            }
            var tiempo = new Date();
            var aux = tiempo.getHours()<10?'0'+tiempo.getHours():tiempo.getHours()
            aux+=':'
            aux+=tiempo.getMinutes()<10?'0'+tiempo.getMinutes():tiempo.getMinutes()
            setModificarHora(aux);
        }else{
            navigate("/", { replace: true });
        }
    }, [actualizar]);
    return (
        <div className='fondoColor vh-100'>
            <Header pagina={5} title="Mi Pedido" callback={() => navigate("/", { replace: true })} />
            <div className='text-center textTitle'>
                <b>Muchas gracias por su Pedido <i className="bi bi-emoji-laughing-fill"></i></b>
            </div>
            <div className='container-fluid col-sm-12 col-md-6 col-xl-4 g-0'>
                <div className='container-fluid overflow-auto configOverFlow'>
                    {listaRecervas.map((item, index) => {
                        return (
                            <CardReserva item={item} actualizar={actualizar}
                                setActualizar={setActualizar} key={index} setSelectItem={setSelectItem}
                                setShowCambioModal={setShowCambioModal}></CardReserva>
                        )
                    })}
                </div>
            </div>
            <div className='container-fluid floatBotones text-center' style={{ bottom: '4px' }}>
                <div className='container-fluid col-sm-12 col-md-6 col-xl-4 '>
                    <button className='btn botonNaranja w-100 text-white' onClick={recogiPedido}>Eliminar Registro pedidos </button>
                </div>
            </div>
            <Modal show={showNoti} contentClassName="bg-transparent "
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header bsPrefix="modal-header m-0 p-0">
                    <div className='verPedidoButon w-100 text-center my-auto py-1'
                        style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
                        <div className='text-white textTitle'>NOTIFICACIÓN</div>
                    </div>
                </Modal.Header>
                <Modal.Body bsPrefix="modal-body m-0 p-0 bg-light">
                    <div className='container-fluid py-4'>
                        {listaRecervas.length !== 0 &&
                            <div className='row row-col-sm-1 row-cols-md-2 g-0'>
                                <div className='col-2 my-auto'>
                                    <i className="fa-solid fa-triangle-exclamation fa-fade fa-2xl"></i>
                                </div>
                                {listaRecervas[0].pedido.tipoPago === 'Q' && listaRecervas[0].tipoPedido === 'DOMICILIO' &&
                                    <div className='col my-auto text-start'>
                                        <p className='textTitle text-start mb-1 fa-fade'>Su compra fue CORRECTA !!</p>
                                        <p className='textInfo text-start mb-1'>{`Numero de Pedido es ${listaRecervas[0].pedidoNro}`}</p>
                                        <p className='textInfo text-start mb-1'>{`El idUnico es ${listaRecervas[0].idTelefonico}`}</p>
                                        <p className='textInfo text-start mb-1'>El costo del Delivery</p>
                                        <p className='textTitle text-start'>Se paga por separado !!</p>
                                        {(listaRecervas[0].ciudad=='990'&&listaRecervas[0].pedido.delivery !== undefined && listaRecervas[0].pedido.delivery.ok !== undefined && listaRecervas[0].pedido.delivery.ok.tarifa != 0) &&
                                            <p className='textInfo text-start mb-1'>{`Precio Referencial es de ${listaRecervas[0].pedido.delivery.ok.tarifa} Bs`}</p>
                                        }
                                        <p className='textTitle text-start'>No se Aceptara devoluciones, si ya no quiere el pedido !!</p>
                                    </div>
                                }
                                {listaRecervas[0].pedido.tipoPago === 'Q' && listaRecervas[0].tipoPedido === 'SUCURSAL' &&
                                    <div className='col my-auto text-start'>
                                        <p className='textTitle text-start mb-1 fa-fade'>Su compra fue CORRECTA !!</p>
                                        <p className='textInfo text-start mb-1'>{`Numero de Pedido es ${listaRecervas[0].pedidoNro}`}</p>
                                        <p className='textInfo text-start mb-1'>{`Su idUnico es ${listaRecervas[0].idTelefonico}`}</p>
                                        <p className='textTitle text-start'>Llegue puntual para no tener un pollo frio... !!</p>
                                        <p className='textTitle text-start'>No se Aceptara devoluciones, si ya no quiere el pedido !!</p>
                                    </div>
                                }
                                {listaRecervas[0].pedido.tipoPago === 'E' &&
                                    <div className='col my-auto'>
                                        <p className='textTitle text-start mb-1 fa-fade'>Su Reserva, fue CORRECTA !!</p>
                                        <p className='textInfo text-center mb-1'>En caja podra </p>
                                        <p className='textTitle text-center'>{`modificar su pedido #${listaRecervas[0].pedidoNro}!!`}</p>
                                        <p className='textInfo text-center mb-1'>Consulte con su numero de pedido o id Unico</p>
                                    </div>
                                }
                            </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer bsPrefix="modal-footer text-center m-0 p-0 mx-auto bg-light w-100">
                    <button className="btn botonNaranja text-light rounded-pill" onClick={() => { setShowNoti(false) }}>
                        Continuar
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal show={showCambioModal} contentClassName="bg-transparent "
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header bsPrefix="modal-header m-0 p-0">
                    <div className='verPedidoButon w-100 text-center my-auto py-1'
                        style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
                        <div className='text-white textTitle'>Modificar Hora</div>
                    </div>
                </Modal.Header>
                <Modal.Body bsPrefix="modal-body m-0 p-0 bg-light">
                    <div className='container-fluid py-4'>
                        <div className='text-center textCard'>
                            ¿Para que hora quiere su Pedido?
                        </div>
                        <input type="time" style={{ width: '120px', alignItems: 'right' }}
                            value={modificarHora}
                            className="form-control textInfo text-center mx-auto"
                            id='hora'
                            onChange={(e) => setModificarHora(e.target.value)} />
                        <div className='text-center my-auto textCard'>
                            Horas
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer bsPrefix="modal-footer text-center m-0 p-0 mx-auto bg-light w-100">
                    <button className="btn botonPlomo text-light rounded-pill" onClick={() => { setSelectItem({}); setShowCambioModal(false) }}>
                        Salir
                    </button>
                    <button className="btn botonNaranja text-light rounded-pill" onClick={() => guardarCambioHora()}>
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
            <MsgAlertas msg={mensageA} show={showAlerta} callback={()=>salirMensaje()}/>
            <PopupLoader loading={loading}/>
        </div>
    )
}
export default RecervasRealizadas