import React, { useState, useEffect } from 'react'
import '../css/CardItem.css'
const server = process.env.REACT_APP_SERVER

function CardItem({ item, setPantalla }) {
    const [costo, setCosto] = useState(0);
    const [nameImg, setNameImg] = useState('');
    const agregarAPedido = (e) => {
        e.preventDefault();
        //console.log(e.target.id);
        localStorage.setItem("elegido", e.target.id);
        setPantalla(1);
    }

    useEffect(() => {
        setCosto(item.PRECIO)
        var nameImgInit = item.ID + (item.DESCRIPCION).replace(/ /g, "");
        setNameImg(nameImgInit.replace('.',''));
    }, [])
    return (
        <div className="col" key={item.ID}>
            <div className='cardItem mb-2'>
                <div className="row row-cols-2" id={item.ID} onClick={agregarAPedido}>
                    <div className='col col-6 my-auto py-0'>
                        <img src={`${server}/imagenes/${nameImg}.png`} id={item.ID} className="card-img-top mx-auto miImgCard" />
                    </div>
                    <div className="col col-6 py-4 " id={item.ID}>
                        <div className="text-start text-uppercase textNaranja" id={item.ID}>{item.DESCRIPCION.replace('.',' ')}</div>
                        <div className='row'>
                            <div className='row row-cols-2'>
                                <div className='col col-6 text-start align-bottom d-flex align-self-end'>
                                    <div className='textBs ' id={item.ID}>Bs {costo}</div>
                                </div>
                                <div className='col col-6 text-end'>
                                    <img id={item.ID} src={`${server}/imagenes/agregar.png`} width="60" height="60"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardItem
