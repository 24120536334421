import { toast } from 'react-toastify';

function msgCorrecto(mensaje){
    toast.success(mensaje, {
        position: "bottom-right",
        autoClose: 900,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "colored",
        progress: undefined,
        });
}
function msgError(mensaje){
    toast.error(mensaje, {
        position: "bottom-right",
        autoClose: 900,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "colored",
        progress: undefined,
        });
}
export default {msgCorrecto,msgError}