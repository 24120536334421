import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import utilsRecervas from '../utils/utilsRecervas';

export default function CardReserva(props) {
    const navigate = useNavigate()
    const { item, actualizar, setActualizar, setSelectItem, setShowCambioModal } = props;
    const [value, setValue] = useState(0);
    const [volrEspera, setValorEspera] = useState(0);
    function calcularHora(hora) {
        var dato = hora.split(':')
        var minutos = (parseInt(dato[0]) * 60 + parseInt(dato[1])) + 30
        var min = Math.trunc(((minutos / 60) - Math.trunc(minutos / 60)) * 60);
        var mins = min < 10 ? ('0' + min) : min
        var horan = Math.trunc(minutos / 60) + ' : ' + mins;
        return horan;
    }
    function irPagoqr() {
        localStorage.setItem('telefonico', item.idTelefonico);
        navigate("/QrPagar", { replace: true });
    }
    setTimeout(() => {
        if (item.estado == 'A') {
            setValue(value + 1);
        }
    }, 4000);
    function eliminarPedido(id) {
        utilsRecervas.eliminarRecerva(id);
        setActualizar(!actualizar);
    }
    function cambiarHora() {
        setSelectItem(item);
        setShowCambioModal(true);
    }
    useEffect(() => {
        if (item.estado == 'A') {
            var minutos = Math.round(((new Date()).getTime() - item.tiempo) / 60000);
            setValorEspera(minutos);
            if (minutos >= 30) {
                utilsRecervas.eliminarRecerva(item.idTelefonico);
                setActualizar(!actualizar);
            }
        }
    }, [value]);
    return (
        <div className='py-2 px-2'>
            <div className='container-fluid rounded fondoDegradado'>
                <div className='row row-cols-2 g-1 '>
                    <div className='col-4 my-auto text-center'>
                        {item.tipoPedido == 'DOMICILIO' &&
                            <div className='text-center container-fluid'>
                                <div className='textMontserratLig text-center'>LLegara al rededor de</div>
                                <div className="badge text-wrap text-dark text-center m-0 p-0" style={{ fontSize: '20px' }}>
                                    {`${40 - volrEspera} min.`}
                                </div>
                            </div>
                        }
                        {item.tipoPedido == 'SUCURSAL' && item.pedido.tipoPago !== 'Q' &&
                            <div className='text-center container-fluid'>
                                <div className='textMontserratLig text-start'>Estara disponible hasta las</div>
                                <div className="badge text-wrap text-dark text-center m-0 p-0" style={{ fontSize: '20px' }}>
                                    {calcularHora(item.pedido.para_hora)}
                                </div>
                            </div>
                        }
                        {item.tipoPedido == 'SUCURSAL' && item.pedido.tipoPago === 'Q' &&
                            <div className='text-center container-fluid'>
                                <div className='textMontserratLig text-start'>Su pedido estará listo, a las </div>
                                <div className="badge text-wrap text-dark text-center m-0 p-0" style={{ fontSize: '20px' }}>
                                    {item.pedido.para_hora}
                                </div>
                            </div>
                        }
                        {item.tipoPedido == 'FEXCO' && item.pedido.tipoPago === 'Q' &&
                            <div className='text-center container-fluid'>
                                <div className='textMontserratLig text-start'>Pedido Fexco, Gracias por su preferencia </div>                                
                            </div>
                        }
                        {item.estado === 'P' &&
                            <button className='text-center btn btn-sm botonNaranja' onClick={() => irPagoqr()}>
                                <i className="fa-solid fa-comment-dollar"></i> Pagar Pedido
                            </button>}
                    </div>
                    <div className='col-8 borderSeparador'>
                        <div className='container-fluid'>
                            <div className='textTitle text-dark '>
                                Pedido # {item.pedidoNro}
                            </div>
                            <div className='textTitle text-dark '>
                                Id Unico {item.idTelefonico}
                            </div>
                            <div className='text-dark textCard'>
                                Nombre: {item.pedido.nombre}
                            </div>
                            <div className='text-dark textCard'>
                                NIT: {item.pedido.nit}
                            </div>
                            {item.tipoPedido == 'SUCURSAL' &&
                                <div className='text-dark textCard'>
                                    Recogera: {item.pedido.recogera}
                                </div>}
                            {item.tipoPedido == 'SUCURSAL' &&
                                <div className='text-dark textCard'>
                                    De sucursal: {item.pedido.sucursal}
                                </div>}
                            {item.tipoPedido == 'SUCURSAL' &&
                                <div className='text-dark textCard'>
                                    Para Horas {item.pedido.para_hora}
                                </div>}
                            <div className='textTitle text-dark'>Total : {item.pedido.total} Bs</div>
                            <button className='btn btn-sm botonRojo bg-gradient mx-1 text-light' onClick={() => eliminarPedido(item.idTelefonico)}>
                                <i className="fa-solid fa-trash-can"></i> Eliminar
                            </button>
                            {item.tipoPedido == 'SUCURSAL' &&
                                <button className='btn btn-sm bg-gradient botonPlomo text-light'
                                    onClick={() => cambiarHora()}>
                                    Cambiar Hora
                                </button>}
                        </div>
                    </div>
                </div>
                {item.tipoPedido == 'SUCURSAL' && item.pedido.tipoPago !== 'Q' &&
                    <div className='text-dark textCard badge bg-light text-center'>
                        En caja Podra modificar su pedido
                    </div>}
                {item.tipoPedido == 'SUCURSAL' && item.pedido.tipoPago == 'Q' &&
                    <div className='text-dark textCard badge bg-light text-center'>
                        Llegue puntual para no tener un pollo frio...
                    </div>}
                {item.tipoPedido == 'FEXCO' && item.pedido.tipoPago == 'Q' &&
                    <div className='text-dark textCard badge bg-light text-center'>
                        {`Ya llegará su pedido FEXCO, Delivery unico ${item.pedido.delivery}bs`}
                    </div>}
                {item.tipoPedido === 'DOMICILIO' && item.ciudad=='990'&&
                    <div className='text-dark textCard badge bg-light text-center'>
                        {`Costo del delivery aproximado ${item.pedido.delivery.ok.tarifa} Bs`}
                    </div>}
                {item.tipoPedido === 'DOMICILIO' && item.ciudad=='991'&&
                    <div className='text-dark textCard badge bg-light text-center'>
                        {`Costo Delivery aparte, a la moto le pagara...`}
                    </div>}
            </div>

        </div>
    )
}
