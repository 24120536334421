import React, { useEffect, useState } from 'react'
import imgLlevar from '../assets/llevar.png';
import imgComer from '../assets/comer.png';
import utilsCarrito from '../utils/utilsCarrito';
function SelectorListaPedido(props) {
    const { actualizar,setActualizar,item } = props;
    const [llevar, setLlevar] = useState(false);

    const accionLLevar=()=>{
        setLlevar(!llevar);
        utilsCarrito.cambiarTipoDespacho(item.idPedido);
        setActualizar(!actualizar);
    }
    useEffect(()=>{
        if(item.tipo_despacho=='A'){
            setLlevar(false);
        }else{
            setLlevar(true);
        }
    },[])
    return (
        <div className={item.tipo_despacho!=='H'?"form-check form-switch my-auto":"d-none"}>
            <input className="form-check-input" type="checkbox" id={item.idPedido} checked={llevar} onChange={accionLLevar} />
            {llevar ? <label className="form-check-label" for={item.idPedido}><img src={imgLlevar}></img></label> : <label className="form-check-label" for="flexSwitchCheckChecked"><img src={imgComer}></img></label>}
        </div>
    )
}

export default SelectorListaPedido
