import utilsVariantes from "./utilsVariantes";

function agregarACarrito() {
    const carritoo = JSON.parse(localStorage.getItem('carrito'));
    const elegido = JSON.parse(localStorage.getItem('elegido'));
    const variante = JSON.parse(localStorage.getItem('variante'));
    if (carritoo === null) {
        var dato = [{
            detalle: elegido,
            variante: JSON.parse(utilsVariantes.sinCantidadCeros(variante))
        }]
        localStorage.setItem('carrito', JSON.stringify(dato))
    } else {
        var adDetalle = [];
        var hayDetalle = false;
        var idHayDetalle=0;
        for (var item of carritoo[0].detalle) {
            if (hayDetalle === false && elegido[0].idMenu === item.idMenu&&elegido[0].tipo_despacho===item.tipo_despacho) {
                hayDetalle = true;
                var dato = {
                    idPedido: item.idPedido,
                    idMenu: item.idMenu,
                    cantidad: item.cantidad + elegido[0].cantidad,
                    precio: item.precio,
                    precioT: item.precioT + elegido[0].precioT,
                    tipo_despacho:item.tipo_despacho
                }
                idHayDetalle=item.idPedido;
                adDetalle.push(dato);
            } else {
                adDetalle.push(item);
            }
        }
        if (hayDetalle === false) {
            adDetalle.push(elegido[0]);
        }
        var adVariante = []
        for (var item of carritoo[0].variante) {
            if (item.cantidad !== 0) {
                adVariante.push(item);
            }
        }
        if (hayDetalle === false) {
            for (var item of variante) {
                if (item.cantidad !== 0) {
                    adVariante.push(item)
                }
            }
        }else{
            for(var item of variante){
                if(item.cantidad!==0){
                    if(exiteVariante(adVariante,item.grupo,item.idProductoVariante,item.idMenu,item.tipo_despacho)){
                        console.log("entro actualizar")
                        for (const mitem of adVariante){
                            if(mitem.grupo==item.grupo && mitem.idProductoVariante==item.idProductoVariante && mitem.idMenu==item.idMenu&&mitem.tipo_despacho==item.tipo_despacho){       
                                mitem.cantidad=mitem.cantidad+item.cantidad;   
                            }
                        } 
                    }else{
                        item.idPedido=idHayDetalle;
                        adVariante.push(item);
                    }
                }
            }
        }
        var dato = [{
            detalle: adDetalle,
            variante: adVariante
        }]
        
        localStorage.setItem('carrito', JSON.stringify(dato));
    }
    localStorage.removeItem('elegido');
    localStorage.removeItem('variante');
}
function exiteVariante(listaVariante,grupo,id,idMenu,despacho){
    for (const item of listaVariante){
        if(item.grupo==grupo && item.idProductoVariante==id && item.idMenu==idMenu && item.tipo_despacho==despacho){
            return true
        }
    }
    return false
}
function actualizarVariante(listaVariante,grupo,id,cantidad,idPedido){
    for (const item of listaVariante){
        if(item.grupo==grupo && item.idProductoVariante==id && item.idPedido==idPedido){       
            item.cantidad=item.cantidad+cantidad;   
        }
    }  
    return listaVariante;
}
function getCarritoVariantes() {
    var cesta = JSON.parse(localStorage.getItem('carrito'));
    return cesta[0].variante;
}
function getCarritoDetalle() {
    var cesta = JSON.parse(localStorage.getItem('carrito'));
    return cesta[0].detalle;
}
function getTotalDetalleCompra() {
    var detalles = getCarritoDetalle();
    var total = 0;
    for (const item of detalles) {
        total = total + item.precioT
    }
    return total;
}
function elimarDelCarrito(idPedido) {
    var cesta = JSON.parse(localStorage.getItem('carrito'))[0];
    var newCesta = [{
        detalle: cesta.detalle.filter((item) => item.idPedido != idPedido),
        variante: cesta.variante.filter((item) => item.idPedido != idPedido)
    }]
    return newCesta;
}
function cambiarTipoDespacho(idPedido){
    var cesta = JSON.parse(localStorage.getItem('carrito'));
    var detalle=cesta[0].detalle.filter((item)=>item.idPedido!==idPedido);
    var variante=cesta[0].variante.filter((item)=>item.idPedido!==idPedido);
    var newCesta=[{detalle,variante}]
    localStorage.setItem('carrito',JSON.stringify(newCesta));
    var newElegido=cesta[0].detalle.filter((item)=>item.idPedido===idPedido);
    var newVariante=cesta[0].variante.filter((item)=>item.idPedido===idPedido);
    if(newElegido[0].tipo_despacho=='A'){
        newElegido[0].tipo_despacho='L';
        for(var ele of newVariante){
            ele.tipo_despacho='L';
        }
    }else{
        newElegido[0].tipo_despacho='A';
        for(var ele of newVariante){
            ele.tipo_despacho='A';
        }
    }
    localStorage.setItem('elegido',JSON.stringify(newElegido));
    localStorage.setItem('variante',JSON.stringify(newVariante))
    agregarACarrito();
}
function hayParaComer(){
    var cesta = JSON.parse(localStorage.getItem('carrito'));
    console.log(cesta);
    if(cesta!==null){
        for(var item of cesta[0].detalle){
            if(item.tipo_despacho=='A'){
                return true;
            }
        }
    }
    return false;
}
export default { agregarACarrito, getCarritoVariantes, getCarritoDetalle, getTotalDetalleCompra, elimarDelCarrito,cambiarTipoDespacho,hayParaComer}