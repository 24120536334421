import React from 'react'
import Modal from 'react-bootstrap/Modal';
import ClipLoader from "react-spinners/ClipLoader";
function PopupLoader(props) {
    const { loading } = props;
    return (
        <Modal
            show={loading}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName='fondoTransparente text-center my-auto'
        >
            <Modal.Body>
                <ClipLoader color='#fa7210' loading={loading} size={150} />
                <div className='text-center fa-fade textMontserratSM text-light'>Espere Por favor...</div>
            </Modal.Body>
        </Modal>
    )
}

export default PopupLoader

/*
{/*
        <div>
            <button id='openLoading' type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop">open</button>
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className='mx-auto text-center'>
                        <button id='closeLoading' type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        <ClipLoader color='#fa7210' loading={loading} size={150} />
                    </div>
                </div>
            </div>
    </div>*/