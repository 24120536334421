import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Header from './Header'
import utilsCarrito from '../utils/utilsCarrito';
import msgToask from '../utils/msgToask';
import utils from '../utils/utils';
import SelectorListaPedido from './SelectorListaPedido';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const server = process.env.REACT_APP_SERVER

export default function ListaPedidos(props) {
  const { pantalla, setPantalla } = props;
  const [pedido, setPedido] = useState([])
  const [actualizar, setActualizar] = useState(false);
  const [totalPagar, setTotalPagar] = useState(0);
  const [sinNada, setSinNada] = useState(false);
  const [show, setShow] = useState(false);
  const [tipoPedido, setTipoPedido] = useState('');
  const [idEliminar, setIdEliminar] = useState(0);
  const [showEliminar, setShowEliminar] = useState(false);
  const handleClose = () => { setPantalla(0); setShow(false) };
  const handleShow = () => setShow(true);
  const eventoClick = (e) => {
    setIdEliminar(e.target.id);
    setShowEliminar(true);
  }
  function eliminarItem() {
    var newPedido = utilsCarrito.elimarDelCarrito(idEliminar)
    localStorage.setItem('carrito', JSON.stringify(newPedido));
    setActualizar(!actualizar);
    msgToask.msgCorrecto("Eliminacion Correcta...");
    setShowEliminar(false);
  }
  useEffect(() => {
    var tp = localStorage.getItem('tipoPedido')
    if (tp === 'DOMICILIO') {
      setTipoPedido('D');
    } else if(tp ==='FEXCO'){
      setTipoPedido('F');
    }else {
      setTipoPedido('S');
    }
    const carrito = JSON.parse(localStorage.getItem('carrito'));
    if (carrito === null) {
      setSinNada(true);
    } else {
      if (carrito[0].detalle.length !== 0) {
        setSinNada(false);
        var listaPedido = []
        if (carrito !== null) {
          for (var item of carrito[0].detalle) {
            var info = utils.getMenuSeleccionado(item.idMenu)
            var infoPedido = {
              idPedido: item.idPedido,
              idMenu: item.idMenu,
              nameImg: (info.ID + info.DESCRIPCION).replace(/ /g, ""),
              descripcion: info.DESCRIPCION,
              cantidad: item.cantidad,
              total: item.precioT,
              tipo_despacho: item.tipo_despacho
            }
            //console.log(infoPedido)
            listaPedido.push(infoPedido);
          }
          setTotalPagar(utilsCarrito.getTotalDetalleCompra())
          setPedido(listaPedido);
        }
      } else {
        setSinNada(true);
        setPedido([]);
        setTotalPagar(0);
      }
    }
  }, [actualizar]);
  return (
    <div className='fondoColor vh-100'>
      <Header pagina={5} title={"Mi Pedido"} callback={() => handleShow()} />
      <div className='container-fluid py-1 text-start col-sm-12 col-md-6 col-xl-4'>
        <div className='table-responsive bg-light vh-70'>
          <table className="table table-striped bg-light vh-70">
            <thead className='cabeceraTable' style={{borderTopLeftRadius:'16px',borderTopRightRadius:'16px'}}>
              <tr>
                <th style={{borderTopLeftRadius:'16px'}}>Cant. </th>
                <th scope="col">Item</th>
                <th scope="col">Bs.-</th>
                <th style={{borderTopRightRadius:'16px'}}></th>
              </tr>
            </thead>
            <tbody className='textTable'>
              {pedido.map((item) => {
                return (
                  <tr key={item.idPedido}>
                    <td>{item.cantidad}</td>
                    <td>{item.descripcion}</td>
                    <td>{item.total}</td>
                    <td>
                      <img src={`${server}/imagenes/eliminar.png`} width="30" height="30" id={item.idPedido} onClick={eventoClick}></img>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className='floatBotones text-center w-100' style={{ bottom: '2%' }}>
        <div className='container-fluid text-center col-sm-12 col-md-6 col-xl-4'>
          {sinNada === false && <div className='contenedorCosto text-center text-white mb-2'
          style={{borderBottomLeftRadius:'16px',borderBottomRightRadius:'16px'}}>
            Bs.- {totalPagar}
          </div>}
          <Link className='rounded-pill btn text-white botones mb-1'
            title='Agregar a Carrito de Compras'
            onClick={() => setPantalla(0)}
            to='/menu'>
            <i className="bi bi-plus-circle "></i> Agregar Productos
          </Link>
          {sinNada === false && tipoPedido === 'D' && <Link className='rounded-pill btn text-white  botonesContenedor'
            title='Siquiente Paso'
            to='/reservar'>
            <i className="bi bi-send-plus-fill "></i> Realizar Pedido
          </Link>}
          {sinNada === false && tipoPedido === 'S' && <Link className='rounded-pill btn text-white  botonesContenedor'
            title='Siquiente Paso'
            onClick={()=>localStorage.removeItem('infUser')}
            to='/datoAdicional'>
            <i className="bi bi-send-plus-fill "></i> Confirmar
          </Link>}
          {sinNada === false && tipoPedido === 'F' && <Link className='rounded-pill btn text-white  botonesContenedor'
            title='Siquiente Paso'
            to='/reservar'>
            <i className="bi bi-send-plus-fill "></i> Realizar Pedido
          </Link>}
        </div>
      </div>
      <Modal show={show} contentClassName="bg-secondary bg-gradient" onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header bsPrefix="modal-header m-0 p-0">
          <div className='verPedidoButon w-100 text-center my-auto py-1'
            style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
            <div className='text-white textTitle'>NOTIFICACIÓN</div>
          </div>
        </Modal.Header>
        <Modal.Body bsPrefix="modal-body m-0 p-0 bg-light">
          <div className='container-fluid py-2'>
            <p className='textInfo text-center mb-1'> <i className="fa-solid fa-triangle-exclamation fa-2xl fa-fade"></i>  Esta seguro de la lista de Pedidos .</p>
          </div>
        </Modal.Body>
        <Modal.Footer bsPrefix="modal-footer text-center m-0 p-0 mx-auto bg-light w-100">
          <Button variant="btn btn-sm text-light botonPlomo" onClick={handleClose}>
            No <i className="fa-solid fa-thumbs-down"></i>
          </Button>
          <Link className="btn btn-sm text-light botonNaranja" to='/menu' onClick={handleClose}>
            Si <i className="fa-solid fa-thumbs-up"></i>
          </Link>
        </Modal.Footer>
      </Modal>
      <Modal show={showEliminar} contentClassName="bg-secondary bg-gradient" onHide={() => setShowEliminar(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header bsPrefix="modal-header m-0 p-0">
          <div className='verPedidoButon w-100 text-center my-auto py-1'
            style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
            <div className='text-white textTitle'>NOTIFICACIÓN</div>
          </div>
        </Modal.Header>
        <Modal.Body bsPrefix="modal-body m-0 p-0 bg-light">
          <div className='container-fluid py-2'>
            <p className='textInfo text-center mb-1'> <i className="fa-solid fa-triangle-exclamation fa-2xl fa-fade"></i>  Esta seguro de Eliminar del Carrito de Compra .</p>
          </div>
        </Modal.Body>
        <Modal.Footer bsPrefix="modal-footer text-center m-0 p-0 mx-auto bg-light w-100">
          <Button variant="btn btn-sm text-light botonPlomo" onClick={() => setShowEliminar(false)}>
            No <i className="fa-solid fa-thumbs-down"></i>
          </Button>
          <button className="btn btn-sm text-light botonNaranja" onClick={() => eliminarItem()}>
            Si <i className="fa-solid fa-thumbs-up"></i>
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
