import React, { useEffect, useRef, useState } from 'react'
import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import msgToask from '../utils/msgToask';
const server = process.env.REACT_APP_SERVER
function MapaLeaflet() {
    const [limite, setLimite] = useState({});
    const [poligonos, setPoligonos] = useState({})
    const [location,setLocation] = useState({"lat": -17.419764104810696, "lng": -66.12958431243898})
    const mapa = useRef(null);
    const markerRef = useRef(null);
    function whenClicked(e) {
        var infoUser = localStorage.getItem('infUser');
        if (infoUser!=undefined){
            infoUser = JSON.parse(infoUser);
            infoUser.latitud = e.latlng.lat;
            infoUser.longitud = e.latlng.lng;
            localStorage.setItem('infoUser',JSON.stringify(infoUser));
        }else{
            localStorage.setItem('infoUser',JSON.stringify({"latitud":e.latlng.lat,"longitud":e.latlng.lng}))
        }
        markerRef.current.setLatLng(e.latlng);
    }

    function onEachFeature(feature, layer) {
        layer.on({
            click: whenClicked
        });
    }
    function getGeometria() {
        fetch(`${server}/json/geometria.geojson`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                setLimite(data);
                L.geoJSON(data,{
                    onEachFeature: onEachFeature,
                    style: function (feature) {
                        return { color: feature.properties.color };
                    }
                }).bindPopup(function (layer) {
                    localStorage.setItem('descripcionFexco',layer.feature.properties.description);
                    return layer.feature.properties.description;
                }).addTo(mapa.current);
            })
            .catch(err => {
                msgToask.msgError(err.message)
            });
        fetch(`${server}/json/empresas.geojson`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                setPoligonos(data);
                L.geoJSON(data, {
                    onEachFeature: onEachFeature,
                    style: function (feature) {
                        return { color: feature.properties.color==undefined?'#BCFFB0':feature.properties.color };
                    }
                }).bindPopup(function (layer) {
                    localStorage.setItem('descripcionFexco',layer.feature.properties.description);
                    return layer.feature.properties.description;
                }).addTo(mapa.current);
            })
            .catch(err => {
                msgToask.msgError(err.message)
            });
    }
    useEffect(() => {
        if (mapa.current == null) {
            var map = L.map('mapaLeaflet',
                {
                    center: [location.lat, location.lng],
                    zoom: 16,
                    maxZoom:22
                }
            )
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OPM</a>'
            }).addTo(map);
            markerRef.current=L.marker([location.lat, location.lng],
                {
                    title: 'Mi Pedido', icon: L.icon({
                        iconUrl: `${server}/imagenes/marker.png`,
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                    })
                }).addTo(map);
            /*map.on('click', function (e) {
                console.log(e.latlng);
            });*/
            mapa.current = map;
            getGeometria();
        }
    }, [])
    return (
        <div id='mapaLeaflet' className='w-100 ' style={{ height: '320px' }}></div>
    )
}

export default MapaLeaflet