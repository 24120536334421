import React, { useState, useEffect, useRef } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import CardItem from './CardItem';
import utils from '../utils/utils';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const server = process.env.REACT_APP_SERVER

function Inicio({ setPagina, pagina, setPantalla }) {
  const navigate = useNavigate();
  const [listaProductos, setListaProductos] = useState([]);
  const [listaApp,setListaApp] = useState([]); 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    localStorage.removeItem('elegido');
    localStorage.removeItem('variante');
    var filtroSucursal = localStorage.getItem('filtroSucursal')
    var filtroCategoria = localStorage.getItem('filtroCategoria')
    if (filtroSucursal === '9' && localStorage.getItem('tipoPedido') === 'SUCURSAL') {
      //setListaProductos(JSON.parse(localStorage.getItem('listamenu')))
      navigate("/", { replace: true });
    } else if (filtroCategoria !== '0' && filtroSucursal !== '0') {
      var reg=localStorage.getItem('ciudad')==991&&localStorage.getItem("tipoPedido")==='DOMICILIO';
      var suc = reg?'12':localStorage.getItem('filtroSucursal');
      var menuSuc = utils.getMenuSucursal(suc).split(':');
      var cat = localStorage.getItem('filtroCategoria')
      var menuCat = utils.getMenuCategoria(cat).split(':');
      var newMenu = utils.getMenuUnion(menuSuc, menuCat);
      setListaProductos(newMenu);
    } else if (filtroCategoria !== '0' && filtroSucursal === '0') {
      var cat = localStorage.getItem('filtroCategoria')
      var menuCat = utils.getMenuCategoria(cat).split(':');
      var newMenu = utils.geMenuUnionCategoria(menuCat);
      setListaProductos(newMenu)
    } else {
      var suc = localStorage.getItem('filtroSucursal')
      var menuCat = utils.getMenuSucursal(suc).split(':');
      var newMenu = utils.geMenuUnionSucursal(menuCat);
      setListaProductos(newMenu)
    }
    if(listaApp.length===0){
      var app = JSON.parse(localStorage.getItem('menuApp')).map(item=>item.ID_MENU)
      setListaApp(app);
    }
  }, [pagina])
  return (
    <div className='py-2 container-fluid col-sm-12 col-md-6 col-xl-4'>
      <div className='w-100'>
        <div className='container-fluid overflow-auto configOverFlow'>
          <div className="row row-cols-1 g-2 g-lg-2 gx-3">
            {listaProductos.map((item) => {
              if((localStorage.getItem('filtroSucursal')!=9)||(localStorage.getItem('filtroSucursal')==9&&listaApp.indexOf(item.ID)!=-1)){
                return (<CardItem item={item} key={item.ID} setPantalla={setPantalla} />)
              }
            }
            )}
          </div>
        </div>
      </div>
      <div className='container-fluid col-sm-12 col-md-6 col-xl-4 fixed-bottom mb-2'>
        <div className='btn-group w-100 text-center m-0 p-0'>
          <button className='btn btn-sm botonPlomo text-light w-50' 
            style={{borderTopLeftRadius:'13px',borderBottomLeftRadius:'13px',borderTopRightRadius:'13px',borderBottomRightRadius:'13px',marginRight:'1px'}}
            onClick={handleShow}>
            <i className="fa-solid fa-circle-xmark"></i> Salir
          </button>
          <Link className='btn btn-sm text-white botonNaranja w-50'
            style={{borderTopLeftRadius:'13px',borderBottomLeftRadius:'13px',borderTopRightRadius:'13px',borderBottomRightRadius:'13px',marginLeft:'1px'}}
            to="/miPedido"
            onClick={() => { setPantalla(0); }}>
            <i className="fa-solid fa-box fa-xl"></i> <span className=''>Mi Pedido</span>
          </Link>
        </div>
      </div>
      <Modal show={show} contentClassName="bg-secondary bg-gradient" onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header bsPrefix="modal-header m-0 p-0">
          <div className='verPedidoButon w-100 text-center my-auto py-1'
            style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
            <div className='text-white textTitle'>NOTIFICACIÓN</div>
          </div>
        </Modal.Header>
        <Modal.Body bsPrefix="modal-body m-0 p-0 bg-light">
          <div className='container-fluid py-2'>
            <p className='textInfo text-center mb-1'> <i className="fa-solid fa-triangle-exclamation fa-2xl fa-fade"></i>  Esta seguro de Salir de la Aplicación de Pedidos .</p>
          </div>
        </Modal.Body>
        <Modal.Footer bsPrefix="modal-footer text-center m-0 p-0 mx-auto bg-light w-100">
          <button className="btn btn-sm text-light botonPlomo" onClick={handleClose}>
            No <i className="fa-solid fa-thumbs-down"></i>
          </button>
          <Link className="btn btn-sm text-light botonNaranja" to='/' onClick={handleClose}>
            Si <i className="fa-solid fa-thumbs-up"></i>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Inicio
