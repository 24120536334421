import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import msg from '../utils/msgToask';
import { useNavigate } from 'react-router-dom';
import '../css/VistaIni.css'
import PopupLoader from './utils/PopupLoader';
import utils from '../utils/utils';
import MensageInformacion from './utils/MensageInformacion';
const server = process.env.REACT_APP_SERVER
export default function VistaInit(props) {
  const { cargoPagina, regional, setCargoPagina, setPantalla } = props;
  const [sucursal, setSucursal] = useState('');
  const [reserva, setReserva] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificar, setNotificar] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate()
  const actualizarDatos = async () => {
    setLoading(true);
    var idSucursal = localStorage.getItem("filtroSucursal");
    await fetch(`${server}/productos/menuSucursalRegional`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ regional, idSucursal })
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        localStorage.setItem("listamenu", JSON.stringify(data));
        setLoading(false);
        msg.msgCorrecto("Menu Actualizado")
      })
      .catch(err => {
        console.log(err);
        msg.msgError(err.message)
      });
  }
  const salirServidor = async (valor) => {
    console.log(valor)
    if (valor) {
      var info = utils.getNameSucursal(localStorage.getItem("filtroSucursal"));
      /*await fetch(`${server}/servidorSucursal/desconectar`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ info })
      })
        .then(res => res.json())
        .then(data => {
          if (data.ok) {
            console.log(data);
            //msg.msgCorrecto(data.ok);
          } else {
            msg.msgError(data.error)
          }
        })
        .catch(err => {
          console.log(err);
          msg.msgError(err.message)
        });*/
      setNotificar(false);
      navigate("/", { replace: true });
    } else {
      setNotificar(false);
    }
  }
  const conectarServidor = async () => {
    /*document.getElementById('openLoading').click();
    setLoading(true);*/
    var info = utils.getNameSucursal(localStorage.getItem("filtroSucursal"));
    await fetch(`${server}/servidorSucursal/conectar`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ info })
    })
      .then(res => res.json())
      .then(data => {
        if (data.ok) {
          console.log(data);
          msg.msgCorrecto(data.ok);
        } else {
          msg.msgError(data.error)
        }

      })
      .catch(err => {
        console.log(err);
        msg.msgError(err.message)
      });
  }
  const getInfoSucursal = async () => {
    var info = utils.getNameSucursal(localStorage.getItem("filtroSucursal"));
    await fetch(`${server}/servidorSucursal/getInfo`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ info })
    })
      .then(res => res.json())
      .then(data => {
        if (data.ok) {
          console.log(data);
          //msg.msgCorrecto(data.ok);
        } else {
          msg.msgError(data.error)
        }

      })
      .catch(err => {
        console.log(err);
        msg.msgError(err.message)
      });
  }
  useEffect(() => {
    var carrito = JSON.parse(localStorage.getItem('recervas'))
    var name = utils.getNameSucursal(localStorage.getItem("filtroSucursal"));
    setSucursal(name.SUCURSAL);
    if (carrito !== null) {
      if (carrito.length == 0) {
        setReserva(false);
      } else {
        setReserva(true);
      }
    }
  }, []);
  return (
    <div className='imagenIni v-100 vh-100'>
      <Header pagina={3} />
      <div className='container-fluid  col-sm-12 col-md-4 '>
        <div className='text-center'>
          <div className='letraSelector text-dark'>
            Pedido{regional == '990' ? ` de Cochabamba` : ` de Santa Cruz`}
          </div>
        </div>
        <div className='container-fluid'>
          <div className='card mb-4'>
            <div className='text-center textCard'>
              Su reserva estará disponible solo <b>30 min.</b> desde su confirmación.
            </div>
          </div>
          {reserva &&
          <div className='w-100'>
            <Link className='btn text-white botonPlomo rounded-pill w-100' to="/misReservas" ><i className="bi bi-cart-check-fill"></i> Mis Reservas</Link>
          </div>
          }
          <div className='py-4'>
            <Link className='btn text-white botonNaranja rounded-pill w-100'  to="/menu" onClick={() => setPantalla(0)}><i className="bi bi-shop"></i> + RESERVA</Link>
          </div>
        </div>
        <a className='btn floatBotones'
          style={{ top: '2%', left: '3%' }}
          title='Configuracion'
          onClick={() => { setMessage(" Quiere Salir ?"); setNotificar(true); }}>
          <img src={`${server}/imagenes/salir.png`} width="60" height="60"></img>
        </a>
        <MensageInformacion msg={message} show={notificar} callback={salirServidor} />
        <PopupLoader loading={loading} />
      </div>
    </div>
  )
}
