const descripcionProductos = 
    {   "1":{presas:"2 Presas de pollo",acompañamiento:"1 Porción de acompañamimento",refresco:"",salsa:"1 salsa de 50g",llajua:"1 vasito de llajua"},
        "2":{presas:"4 Presas de pollo",acompañamiento:"1 Porción de acompañamimento",refresco:"",salsa:"1 salsa de 50g",llajua:"1 vasito de llajua"},
        "3":{presas:"2 Presas de pollo",acompañamiento:"1 Porción de acompañamimento",refresco:"1 Vaso de Refresco mediano",salsa:"1 salsa de 50g",llajua:"1 vasito de llajua"},
        "4":{presas:"4 Presas de pollo",acompañamiento:"1 Porción de acompañamimento",refresco:"1 Vaso de Refresco mediano",salsa:"1 salsa de 50g",llajua:"1 vasito de llajua"},
        "5":{presas:"2 Presas de pollo",acompañamiento:"",refresco:"",salsa:"1 salsa de 50g",llajua:"1 vasito de llajua"},
        "6":{presas:"4 Presas de pollo",acompañamiento:"",refresco:"",salsa:"1 salsa de 50g",llajua:"1 vasito de llajua"},
        "30":{presas:"250 gr. de pollo frito",acompañamiento:"1 Porción de acompañamiento",refresco:"1 Vaso de Refresco mediano",salsa:"1 salsa de 50g",llajua:"1 vasito de llajua"},
        "29":{presas:"250 gr. de pollo frito",acompañamiento:"1 Porción de acompañamiento",refresco:"",salsa:"1 salsa de 50g",llajua:"1 vasito de llajua"},
        "28":{presas:"250 gr. de pollo frito",acompañamiento:"",refresco:"",salsa:"1 salsa de 50g",llajua:"1 vasito de llajua"},
        "54":{presas:"1 Presa de pollo",acompañamiento:"1 Porción de acompañamiento",refresco:"1 Vaso de Refresco mediano",salsa:"1 salsa de 50g",llajua:"1 vasito de llajua"},
    }

export default descripcionProductos;