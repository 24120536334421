import { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contenido from "./component/Contenido";
import { ToastContainer } from 'react-toastify';
import VistaInit from './component/VistaInit';
import ListaPedidos from './component/ListaPedidos';
import RealizarReserva from './component/RealizarReserva';
import RecervasRealizadas from './component/RecervasRealizadas';
import './css/App.css'
import ConfigInicio from './component/ConfigInicio';
import QrPagoVista from './component/QrPagoVista';
import DatoAdicional from './component/DatoAdicional';
import PrincipalFexoPedido from './component/PrincipalFexoPedido';
import MapaLeaflet from './component/MapaLeaflet';
const server = process.env.REACT_APP_SERVER
function App() {
  const [carrito, setCarrito] = useState(0);
  const [pantalla, setPantalla] = useState(0);
  const [cargoPagina, setCargoPagina] = useState(false);
  const [regional, setRegional] = useState('0');
  useEffect(() => {
    localStorage.setItem('filtroSucursal', 9);
    localStorage.setItem("filtroCategoria", -100);
    /*fetch(`${server}/sucursales/configuracionHorarios`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log(data.ok)
        if (data.ok) {
          localStorage.setItem("configHR", JSON.stringify(data.ok[0]));
        } else {
          msgToask.msgError(data.error);
        }
      })
      .catch(err => {
        msgToask.msgError(err.message)
      });*/
  }, [cargoPagina]);
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ConfigInicio regional={regional} setRegional={setRegional} setPantalla={setPantalla} />} />
        <Route path='/FEXCO' element={<ConfigInicio regional={regional} setRegional={setRegional} setPantalla={setPantalla} />} />
        <Route path="/inicio" element={
          <VistaInit cargoPagina={cargoPagina} regional={regional} setCargoPagina={setCargoPagina} setPantalla={setPantalla} />} />
        <Route path="/menu"
          element={
            <Contenido
              setCarrito={setCarrito}
              carrito={carrito}
              pantalla={pantalla}
              setPantalla={setPantalla}
            />} />
        <Route path="/miPedido"
          element={
            <ListaPedidos pantalla={pantalla}
              setPantalla={setPantalla} />
          } />
        <Route path="/reservar" element={<RealizarReserva regional={regional} />} />
        <Route path="/QrPagar" element={<QrPagoVista />} />
        <Route path="/misReservas" element={<RecervasRealizadas />} />
        <Route path='/datoAdicional' element={<DatoAdicional regional={regional} />} />
        <Route path='/pedidoFexco' element={<PrincipalFexoPedido regional='990'/>} />
        <Route path='/mapaLeaflet' element={<MapaLeaflet regional='990'/>} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </BrowserRouter>
  );
}

export default App;
