import React, { useEffect, useState } from 'react'
import utils from '../utils/utils';
import '../css/SideBar.css'
import listaCategoria from '../utils/nameCategorias'
const server = process.env.REACT_APP_SERVER
export default function ItemCategoria(props) {
    const {item,setPagina, pagina,setTitleCat}=props;
    const [categori,setCategori]=useState(item.ID_CATEGORIA);
    const [estado,setEstado]=useState(false);
    const filtroCategoria = (e) => {   
        setTitleCat(item.CATEGORIA);
        localStorage.setItem("filtroCategoria", e.target.id);
        setPagina(pagina + 1);
    }
    useEffect(()=>{
        var categoria= localStorage.getItem('filtroCategoria')
        if(categoria==categori){
            setTitleCat(item.CATEGORIA);
            setEstado(true);
        }else{
            setEstado(false);
        }
    },[pagina]);
    return (
        <button className={estado?"btn btn-sm itemSiSelect":"btn btn-sm itemSi "} title={`Mostrar categoria ${item.CATEGORIA}`}>
            <a className="nav-link align-middle m-0 p-0" id={item.ID_CATEGORIA} onClick={filtroCategoria}>
                <img id={item.ID_CATEGORIA} src={`${server}/imagenes/${listaCategoria[item.ID_CATEGORIA]}`}  className='m-0 p-0' width="45" height="45" />
                {/*<span id={item.ID_CATEGORIA} className="ms-1 d-none d-sm-inline text-dark fs-6" >{utils.capitalize(item.CATEGORIA)}</span>*/}
            </a>
        </button>
    )
}
