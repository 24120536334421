import React, { useEffect, useState } from 'react'
import Carousel from "react-multi-carousel";
import ItemCategoria from './ItemCategoria';
import "react-multi-carousel/lib/styles.css";
import imgking from '../assets/logo_kingdom_256px.png';
import '../css/Header.css'
const server = process.env.REACT_APP_SERVER
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 5,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 5,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export default function Header(props) {
    const { pagina, setPagina, title, callback, cantidad } = props;
    const [categorias, setCategorias] = useState([]);
    const [titleCat, setTitleCat] = useState('');
    const getVista = (valor) => {
        if (valor === 0) {
            return (
                <div className='container-fluid myHeader col-sm-12 col-md-6 col-xl-4'>
                    <div className='row row-cols-2 g-1'>
                        <div className='col-2 my-auto'>
                            <div className="text-start" onClick={() => callback()}>
                                <img src={`${server}/imagenes/atras.png`} width="60" height="60"></img>
                            </div>
                        </div>
                        <div className='col-8 text-center '>
                            <div className="py-1 align-items-center pb-1 mb-md-0 me-md-auto ">
                                <img src={imgking} width="100" height="80" className="d-inline-block align-text-top text-center" />
                            </div>
                        </div>
                        <div className='col-2'>

                        </div>
                    </div>
                </div>
            )
        } else if (valor >= 10) {
            return (
                <div className='container-fluid myHeader col-sm-12 col-md-6 col-xl-4 text-center'>
                    <div className='text-center'>
                        <div className='titulo m-0 p-0'>MENU</div>
                        <div className='py-1' >
                            <Carousel
                                swipeable={true}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                arrows={false}
                                infinite={false}
                                autoPlay={false}
                                keyBoardControl={false}
                                //customTransition="all .5"
                                transitionDuration={false}
                                containerClass="carousel-container m-0 p-0"
                                dotListClass="custom-dot-list-style"
                            >
                                {categorias.map((item) => {
                                    if(item.ID_CATEGORIA!==-104){
                                    return (
                                        <ItemCategoria item={item} setPagina={setPagina} pagina={pagina} key={Math.abs(item.ID_CATEGORIA)} setTitleCat={setTitleCat} />
                                    );
                                    }
                                })

                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
            )
        } else if (valor === 1) {
            return (
                <div className='container-fluid myHeader col-sm-12 col-md-6 col-xl-4'>
                    <div className='row row-cols-3 g-1'>
                        <div className='col-2 my-auto'>
                            <div className="text-start" onClick={() => callback()}>
                                <img src={`${server}/imagenes/atras.png`} width="40" height="40"></img>
                            </div>
                        </div>
                        <div className='col-8 text-center my-auto'>
                            <div className="py-1 align-items-center pb-1 mb-md-0 me-md-auto ">
                                <div className='text-dark text-uppercase text-center titulo'>{title}</div>
                                <div className='text-dark py-1 textMontserratLig'>tiene para seleccionar </div>
                            </div>
                        </div>
                        <div className='col-2 text-end my-auto text-center'>
                            <div className='text-dark textInicio'>{`${cantidad} Cant.`}</div>
                        </div>
                    </div>
                </div>
            )
        } else if (valor === 2) {
            return (
                <div className='container-fluid myHeader col-sm-12 col-md-6 col-xl-4'>
                    <div className='row row-cols-3 g-1'>
                        <div className='col my-auto py-1'>
                            <div className="text-start" onClick={() => callback()}>
                                <img src={`${server}/imagenes/atras.png`} width="40" height="40"></img>
                            </div>
                        </div>
                        <div className='col text-center'>
                            <div className="py-1 align-items-center pb-1 mb-md-0 me-md-auto ">
                                <img src={imgking} width="90px" height="70px" className="d-inline-block align-text-top text-center" />
                            </div>
                        </div>
                        <div className='col text-center my-auto'>
                            <div className='text-uppercase text-center titulo'>{title}</div>
                        </div>
                    </div>
                </div>
            )
        } else if (valor === 3) {
            return (
                <div className='container-fluid myHeader2 col-sm-12 col-md-6 col-xl-4'>
                    <div className='row row-cols-3 g-1'>
                        <div className='col my-auto py-1'>
                            <div className="text-start" onClick={() => callback()}>
                                <img src={`${server}/imagenes/atras.png`} width="60" height="60"></img>
                            </div>
                        </div>
                        <div className='col text-center'>
                            <div className="py-1 align-items-center pb-1 mb-md-0 me-md-auto ">
                                <img src={imgking} width="100px" height="80px" className="d-inline-block align-text-top text-center" />
                            </div>
                        </div>
                        <div className='col text-center my-auto'>
                            <div className='text-uppercase text-center titulo'>{title}</div>
                        </div>
                    </div>
                </div>
            )
        } else if (valor === 4) {
            return (
                <div className='container-fluid text-center'>
                    <div className="py-2 align-items-center pb-3 mb-md-0 me-md-auto ">
                        <img src={imgking} width="100px" height="80px" className="d-inline-block align-text-top text-center" />
                    </div>
                </div>
            )
        } else if (valor === 5) {
            return (
                <div className='container-fluid myHeader col-sm-12 col-md-6 col-xl-4'>
                    <div className='row row-cols-3 g-1'>
                        <div className='col my-auto'>
                            <div className="text-start" onClick={() => callback()}>
                                <img src={`${server}/imagenes/atras.png`} width="40" height="40"></img>
                            </div>
                        </div>
                        <div className='col text-center my-auto'>
                            <div className='text-uppercase text-center titulo'>{title}</div>
                        </div>
                        <div className='col text-end '>
                            <div className="py-1 align-items-center pb-1 mb-md-0 me-md-auto ">
                                <img src={imgking} width="70px" height="50px" className="d-inline-block align-text-top text-center" />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else if (valor === 6) {
            return (
                <div className='container-fluid myHeader col-sm-12 col-md-6 col-xl-4'>
                    <div className='row row-cols-3 g-1'>
                        <div className='col my-auto py-1'>
                            <div className="text-start d-none" onClick={() => callback()}>
                                <img src={`${server}/imagenes/atras.png`} width="40" height="40"></img>
                            </div>
                        </div>
                        <div className='col text-center my-auto'>
                            <div className='text-uppercase text-center titulo'>{title}</div>
                        </div>
                        <div className='col text-end'>
                            <div className="py-1 align-items-center pb-1 mb-md-0 me-md-auto ">
                                <img src={imgking} width="70px" height="50px" className="d-inline-block align-text-top text-center" />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    useEffect(() => {
        if (categorias.length === 0) {
            setCategorias(JSON.parse(localStorage.getItem('menucategorias')))
        }
    }, [pagina])
    return (<>
        {getVista(pagina)}
    </>
    )
}
