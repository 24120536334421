import React, {  useState } from 'react'
import Inicio from './Inicio'
import '../css/Contenido.css'
import Pedido from './Pedido'
import Header from './Header';
import CantidadProducto from './CantidadProducto'
import RealizarReserva from './RealizarReserva'
import RecervasRealizadas from './RecervasRealizadas'

function Contenido({setCarrito, carrito,pantalla, setPantalla}) {
  const [pagina,setPagina]=useState(0);
  function callbackBack(){
    if(pantalla===1){
      setPantalla(0);
    }
  }
  if (pantalla === 0) {
    return (
      <div className='fondoConfig vh-100 '>
        <Header pagina={pagina===0?10:pagina} setPagina={setPagina}  />
        <Inicio setPagina={setPagina} pagina={pagina} setPantalla={setPantalla} />
      </div>
    )
  } else if (pantalla === 1) {
    return (
      <div className='fondoColor vh-100'>
        <Header pagina={0} title="Cantidad" callback={()=>callbackBack()}/>
        <CantidadProducto setCarrito={setCarrito} carrito={carrito} setPantalla={setPantalla}/>
      </div>
    )
  } else if(pantalla === 2){
    return (
      <div className='fondoColor vh-100'>
        <Pedido setPantalla={setPantalla} setCarrito={setCarrito} carrito={carrito}/>
      </div>
    )
  } else if(pantalla ===3){
    return(
      <div className='overflow-auto configOverFlow'>
        <RealizarReserva setPantalla={setPantalla} setCarrito={setCarrito} carrito={carrito}/>
      </div>
    )
  } else if(pantalla === 4){
    return(
      <div className='overflow-auto configOverFlow'>
        <RecervasRealizadas setPantalla={setPantalla} setCarrito={setCarrito} carrito={carrito}/>
      </div>
    )
  }
}

export default Contenido
