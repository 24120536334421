import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import msg from '../utils/msgToask';
import PopupLoader from './utils/PopupLoader';
import utils from '../utils/utils';
import Modal from 'react-bootstrap/Modal';
import utilsRecervas from '../utils/utilsRecervas';
import MensageInformacion from './utils/MensageInformacion';
import MsgAlertas from './utils/MsgAlertas';
import imgInit from '../assets/vistaIni.png'
import MapaLeaflet from './MapaLeaflet';
import MapaLef from './MapaLef';
const server = process.env.REACT_APP_SERVER

function ConfigInicio(props) {
    const { regional, setRegional, setPantalla } = props;
    const navigate = useNavigate()
    const [config, setConfig] = useState(0);
    const [sucursales, setSucursales] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showNoti, setShowNoti] = useState(false);
    const [message, setMessage] = useState('');
    const [notificar, setNotificar] = useState(false);
    const [telefono, setTelefono] = useState('');
    const [selectDir, setSelectDir] = useState(0);
    const [direcciones, setDirecciones] = useState([]);
    const [newDir, setNewDir] = useState(false);
    const [alerta, setAlerta] = useState(false);
    const [hayPedidos, setHayPedidos] = useState(false);
    const [banderaK, setBanderaK] = useState(true);
    const [sucursalesActivas, setSucursalesActivas] = useState([]);
    const [hayFexco,setHayFexco] = useState(false);
    const nextConfig = async (reg, tipo) => {
        if (reg !== '0') {
            await fetch(`${server}/sucursales/regional/${reg}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(data => {
                    setSucursales(data.sucursales);
                    localStorage.setItem("ciudad", reg);
                    localStorage.setItem("sucursales", JSON.stringify(data.sucursales));
                    localStorage.setItem("menucategorias", JSON.stringify(data.menuCategoria));
                    localStorage.setItem("menuApp", JSON.stringify(data.menuApp));
                    localStorage.setItem("filtroCategoria", -100);
                    setLoading(false);
                    //setPantalla(0);
                })
                .catch(err => {
                    console.log(err);
                    msg.msgError(err.message)
                });
        } else {
            msg.msgError("Seleccione la Regional")
        }
    }
    const conectarServidor = async () => {
        var info = utils.getNameSucursal(localStorage.getItem("filtroSucursal"));
        await fetch(`${server}/servidorSucursal/conectar`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ info })
        })
            .then(res => res.json())
            .then(data => {
                if (data.ok) {
                    msg.msgCorrecto(data.ok);
                } else {
                    msg.msgError(data.error)
                }

            })
            .catch(err => {
                console.log(err);
                msg.msgError(err.message)
            });
    }
    const selectSucursal = async (e) => {
        setLoading(true);
        var idSucursal = e.target.id;
        //if (validarOpenSucursal('' + idSucursal)) {
        localStorage.setItem("filtroSucursal", e.target.id);
        //await conectarServidor();
        await getMenuSucursal(idSucursal);
        /*} else {
            setLoading(false);
            setNotificar(true);
            setMessage('Aun no podemos recibir su pedido intente mas tarde, Por favor !!!');
        }*/
    }
    async function getMenuSucursal(idSucursal) {
        await fetch(`${server}/productos/menuSucursalRegional`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ regional, idSucursal })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                localStorage.setItem("listamenu", JSON.stringify(data));
                setLoading(false);
                navigate("/menu", { replace: true });
            })
            .catch(err => {
                console.log(err);
                msg.msgError(err.message)
            });
    }
    function getDirecciones() {
        var info = utils.getNameSucursal(localStorage.getItem("filtroSucursal"));
        fetch(`${server}/servidorSucursal/obtenerUbicaciones`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'REGIONAL': regional, 'TELEFONO': telefono })
        })
            .then(res => res.json())
            .then(data => {
                if (data.ok) {
                    if (data.ok.length === 0) {
                        setDirecciones([]);
                        setConfig(3);
                    } else {
                        setDirecciones(data.ok);
                        setConfig(4);
                    }
                } else {
                    setConfig(3);
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                msg.msgError(err.message)
            });
    }
    function guardarLocalizacionFexco() {
        var ub = document.getElementById('direccion').value;
        var valit = validarOpenSucursal('29');
        if (valit.estado === true) {
            if (telefono !== '' && ub != '') {
                if (telefono.length === 8 && (telefono[0] === '7' || telefono[0] === '6')) {
                    var infoUser = localStorage.getItem('infoUser');
                    if (infoUser != undefined) {
                        infoUser = JSON.parse(infoUser);
                        var nombreStant = localStorage.getItem("descripcionFexco");
                        setLoading(true);
                        fetch(`${server}/servidorSucursal/ingresarUbicacion`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                'tipo': 'F',
                                'TELEFONO': telefono,
                                'direccion': nombreStant,
                                'longitud': infoUser.longitud,
                                'latitud': infoUser.latitud,
                                'aclaraciones': ub,
                                'regional': '990'
                            })
                        })
                            .then(res => res.json())
                            .then(data => {
                                if (data.ok) {
                                    console.log(data.ok)
                                    infoUser.idDireccion = data.ok.V_ID;
                                    infoUser.delivery = data.ok.delivery;
                                    infoUser.telefono = telefono;
                                    localStorage.setItem('infUser', JSON.stringify(infoUser));
                                    localStorage.setItem("filtroSucursal", 29);
                                    getMenuSucursal(29);
                                } else {
                                    setLoading(false);
                                    msg.msgError(data.error);
                                }
                            })
                            .catch(err => {
                                console.log(err);
                                msg.msgError(err.message)
                            });
                    } else {
                        setMessage("Seleccione la ubicacion en la FEXCO, para su pedido ...")
                        setAlerta(true);
                    }
                } else {
                    setMessage("colocar un numero valido, por favor ...")
                    setAlerta(true);
                }
            } else {
                setMessage("colocar su número y la direccion por favor ...")
                setAlerta(true);
            }
        } else {
            if (valit.tipo === 'H') {
                var info = utilsRecervas.getHorarioSucursalRegional(regional, 29);
                var horaIni = Math.trunc(info[4] / 60);
                var horaFin = Math.trunc(info[5] / 60);
                setMessage("Aun no podemos tomar tu pedido, gracias. Intente nuevamente mas tarde entre " + horaIni + ' - ' + horaFin + ' Horas.');
            } else if (valit.tipo === 'N') {
                setMessage(`No estamos permitiendo pedidos en ${regional == '990' ? 'COCHAMBAMBA' : 'SANTA CRUZ'}, gracias Intente mas tarde por favor ...`);
            } else {
                setMessage("Estamos en mantenimiento, para relizar tu pedido KINGDOM ENVIA, intente nuevamente mas tarde, gracias ...");
            }
            setAlerta(true);
        }
    }
    function guardarLocalizacion() {
        var info = JSON.parse(localStorage.getItem('infUser'));
        var calleP = document.getElementById('callePrincipal').value;
        var calleS = document.getElementById('calleSegundaria').value;
        var nrocasa = document.getElementById('nroDomicilio').value;
        var ub = document.getElementById('direccion').value;
        if (nrocasa !== '' && ub !== '' && calleP !== '' && calleS !== '') {
            if (info.latitud !== undefined) {
                setLoading(true);
                fetch(`${server}/servidorSucursal/ingresarUbicacion`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'tipo': 'T',
                        'TELEFONO': telefono,
                        direccion: calleP + ' ' + calleS + ' ' + ' Nro de CASA : ' + nrocasa + ' ' + ub,
                        longitud: info.longitud,
                        latitud: info.latitud,
                        aclaraciones: '',
                        regional
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        if (data.ok) {
                            console.log(data.ok)
                            info.telefono=telefono;
                            info.idDireccion = data.ok.V_ID;
                            info.delivery = data.ok.delivery;
                            if (info.delivery.ok.tarifa !== 0 || info.delivery.error) {
                                localStorage.setItem('infUser', JSON.stringify(info));
                                localStorage.setItem("filtroSucursal", 9);
                                getMenuSucursal(localStorage.getItem('ciudad') == '990' ? 9 : 12);
                            } else {
                                setLoading(false);
                                setMessage("La direccion se encuentra fuera de nuestra area de trabajo, escoja otra por favor...")
                                setAlerta(true);
                            }
                        } else {
                            setLoading(false);
                            msg.msgError(data.error);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        msg.msgError(err.message)
                    });
            } else {
                setMessage("colocar su ubicación en el mapa por favor .")
                setAlerta(true);
            }
        } else {
            setMessage("Colocar la direccón de envio de pedido !")
            setAlerta(true);
        }
    }
    function validarOpenSucursal(suc) {
        var infor = utilsRecervas.getHorarioSucursalRegional(regional, 9);
        console.log(infor);
        var hro = 0, hrc = 0
        if (infor[infor.indexOf(3)] != 0) {
            hro = infor[4];
            hrc = infor[5];
        }
        var info = utilsRecervas.getHorarioSucursal(9)
        var horaIni = info[info.indexOf(suc) + 1];
        var horaFin = info[info.indexOf(suc) + 2];
        var day = new Date()
        var dayminutes = (day.getHours() * 60) + day.getMinutes();
        if (infor[3] != '0' && infor[3] != '1') {
            if (infor[8].indexOf('K') != -1 && infor[10].indexOf('Q') != -1) {
                setBanderaK(true);
                if ((dayminutes >= horaIni && (hro == 0 || dayminutes >= hro)) && (dayminutes < horaFin && (hrc == 0 || dayminutes < hrc))) {
                    return { "estado": true, "tipo": 'H' };
                } else {
                    return { "estado": false, "tipo": 'H' }
                }
            } else {
                setBanderaK(false);
                return { "estado": false, "tipo": 'K' };
            }
        } else {
            setBanderaK(false);
            return { "estado": false, "tipo": 'N' };
        }
    }
    async function irADireccion() {
        if (telefono !== '') {
            if (telefono.length === 8 && (telefono[0] === '7' || telefono[0] === '6')) {
                if (regional !== '0') {
                    var valit = validarOpenSucursal('9');//
                    if (valit.estado === true) {
                        setLoading(true);
                        //await conectarServidor();
                        localStorage.setItem('infUser', JSON.stringify({ telefono }));
                        getDirecciones();
                    } else {
                        if (valit.tipo === 'H') {
                            var info = utilsRecervas.getHorarioSucursalRegional(regional, 9);
                            var horaIni = Math.trunc(info[4] / 60);
                            var horaFin = Math.trunc(info[5] / 60);
                            setMessage("Aun no podemos tomar tu pedido, gracias. Intente nuevamente mas tarde entre " + horaIni + ' - ' + horaFin + ' Horas.');
                        } else if (valit.tipo === 'N') {
                            setMessage(`No estamos permitiendo pedidos en ${regional == '990' ? 'COCHAMBAMBA' : 'SANTA CRUZ'}, gracias Intente mas tarde por favor ...`);
                        } else {
                            setMessage("Estamos en mantenimiento, para relizar tu pedido KINGDOM ENVIA, intente nuevamente mas tarde, gracias ...");
                        }
                        setAlerta(true);
                    }
                } else {
                    setMessage("Seleccione su CIUDAD por favor!")
                    setAlerta(true);
                }
            } else {
                setMessage("Numero de celular invalido!")
                setAlerta(true);
            }
        } else {
            setMessage("Ingrese su número de celular por favor!")
            setAlerta(true);
        }
    }
    function cambiarRegional(valor) {
        setLoading(true);
        localStorage.setItem("ciudad", valor);
        setRegional(valor);
        nextConfig(valor);
    }
    function callbackNotificador(valor) {
        setNotificar(false);
    }
    function seleccionarUbicacion(valor) {
        if (selectDir !== 0) {
            setLoading(true);
            var localizacion = direcciones.filter((item) => item.ID_DIRECCION == selectDir)[0]
            fetch(`${server}/reservare/miTarifaReferencial`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    latitud: localizacion.GEO_LATITUD, longitud: localizacion.GEO_LONGITUD
                })
            })
                .then(res => res.json())
                .then(data => {
                    setNewDir(false);
                    var info = JSON.parse(localStorage.getItem('infUser'));
                    info.idDireccion = selectDir;
                    info.delivery = data;
                    if (data.ok.tarifa !== 0 || data.error) {
                        localStorage.setItem('infUser', JSON.stringify(info));
                        getMenuSucursal(localStorage.getItem('ciudad') == '990' ? 9 : 12);
                    } else {
                        setLoading(false);
                        setMessage("La direccion se encuentra fuera de nuestra area de trabajo, escoja otra por favor...")
                        setAlerta(true);
                    }
                })
                .catch(err => {
                    setMessage("Error: " + err.message)
                    setAlerta(true);
                    setLoading(false);
                });
        } else {
            setMessage('Seleccione una ubicacion, por favor.')
            setAlerta(true);
        }
    }
    function irPedidoDomicilio() {
        setLoading(true);
        fetch(`${server}/sucursales/configuracionHorarios`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                if (data.ok) {
                    localStorage.setItem("configHR", JSON.stringify(data.ok[0]));
                    localStorage.removeItem('carrito');
                    setRegional('0');
                    if (localStorage.getItem('filtroSucursal') !== 9) {
                        localStorage.setItem('filtroSucursal', 9);
                    }
                    setShowNoti(true);
                    localStorage.setItem('tipoPedido', 'DOMICILIO');
                } else {
                    setMessage("Intente nuevamente mas tarde por favor...");
                    setAlerta(true);
                }
            })
            .catch(err => {
                setMessage("error", err.message);
                setAlerta(true);
            });
    }
    function irPedidoFexco() {
        localStorage.removeItem('infoUser');
        cambiarRegional('990')
        setLoading(true);
        fetch(`${server}/sucursales/configuracionHorarios`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                if (data.ok) {
                    localStorage.setItem("configHR", JSON.stringify(data.ok[0]));
                    localStorage.removeItem('carrito');
                    if (localStorage.getItem('filtroSucursal') !== 29) {
                        localStorage.setItem('filtroSucursal', 29);
                    }
                    setShowNoti(true);
                    localStorage.setItem('tipoPedido', 'FEXCO');
                } else {
                    setMessage("Intente nuevamente mas tarde por favor...");
                    setAlerta(true);
                }
            })
            .catch(err => {
                setMessage("error", err.message);
                setAlerta(true);
            });
    }
    function irRecojoSucursal() {
        setLoading(true);
        fetch(`${server}/sucursales/configuracionHorarios`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                if (data.ok) {
                    localStorage.setItem("configHR", JSON.stringify(data.ok[0]));
                    localStorage.setItem('tipoPedido', 'SUCURSAL');
                    localStorage.removeItem('carrito');
                    setSucursalesActivas(utilsRecervas.getListaSucursalesActivos())
                    setRegional('0');
                    setConfig(1);
                } else {
                    setMessage("Intente nuevamente mas tarde por favor...");
                    setAlerta(true);
                }
            })
            .catch(err => {
                setMessage("error", err.message);
                setAlerta(true);
            });
    }
    function callbackBack() {
        if (config === 2) {
            setConfig(0);
        } else if (config === 4) {
            setConfig(2);
        } else if (config === 3 && direcciones.length !== 0) {
            setConfig(4);
        } else if (config === 3 && direcciones.length === 0) {
            setConfig(2);
        } else if (config === 1) {
            setConfig(0)
        } else if (config == 5) {
            setConfig(0);
        }
    }
    function recogeraRegional(reg) {
        var infor = utilsRecervas.getHorarioSucursalRegional(reg, 9);
        if (infor[3] !== '0' && infor[3] != '1') {
            if (infor[8].indexOf('R') != -1) {
                setLoading(true);
                setRegional(reg);
                nextConfig(reg);
            } else {
                setMessage(`No estamos permitiendo, pedidos recogera de sucursal en ${reg == '990' ? 'COCHABAMBA' : 'SANTA CRUZ'}, intente mas tarde por favor ...`);
                setAlerta(true);
            }
        } else {
            setMessage(`No estamos permitiendo pedidos en ${reg == '990' ? 'COCHAMBAMBA' : 'SANTA CRUZ'}, gracias Intente mas tarde por favor ...`);
            setAlerta(true);
        }
    }
    function haySucursal(dato) {
        var sucLis = sucursalesActivas.filter((item) => item == dato.ID_SUCURSAL);
        if (sucLis.length !== 0) {
            return true;
        }
        return false;
    }
    function getInfoConfig(){
        fetch(`${server}/sucursales/getConfigPedidos`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.ok) {
                    var info = data.ok[0].DATO_CADENA;
                    if(info=='S'){
                        setHayFexco(true);
                    }else{
                        setHayFexco(false);
                    }
                } else {
                    setHayFexco(false);
                }
            })
    }
    useEffect(() => {
        getInfoConfig()
        var pd = JSON.parse(localStorage.getItem('reservas'))
        if (pd !== null && pd !== undefined) {
            if (pd.length !== 0) {
                setHayPedidos(true);
            } else {
                setHayPedidos(false);
            }
        }
        const currentUrl = window.location.pathname;
        if(currentUrl=='/FEXCO'){
            document.getElementById('btnFexcoPedido').click()
        }
    }, [])
    return (
        <div className={`${config !== 0 ? 'fondoConfig' : 'fondoInical'} w-100 vh-100`}>
            {(config === 0) && <Header pagina={4} />}
            {config == 1 && <Header pagina={3} callback={() => callbackBack()} />}
            {(config === 2 || config === 3 || config === 4 || config === 5) && <Header pagina={2} title="" callback={() => callbackBack()} />}
            <div className='container-fluid col-sm-12 col-md-6 col-xl-4'>
                {config === 0 && <div className='mx-auto py-2'>
                    <div className='text-center textInicio'>
                        Bienvenid@ a Chicken's kingdom
                    </div>
                    <div className='container-fluid text-center'>
                        <img src={imgInit} width='60%' className='img-fluid'></img>
                    </div>
                </div>}
                {config === 0 &&
                    <div className='py-5'>
                        <div className='container-fluid py-2'>
                            <div className='container-fluid mb-2'>
                                <button className='btn w-100 rounded-pill text-white botonPlomo'
                                    onClick={() => irPedidoDomicilio()}>
                                    Pedir a Domicilio
                                </button>
                            </div>
                            <div className='container-fluid mb-2'>
                                <button className='btn w-100 rounded-pill text-white botonPlomo'
                                    onClick={() => irRecojoSucursal()} >
                                    Recogere de Sucursal
                                </button>
                            </div>
                            {hayFexco==true&&<div className='container-fluid mb-2'>
                                <button className='btn w-100 rounded-pill text-white botonPlomo'
                                    id='btnFexcoPedido'
                                    onClick={() => irPedidoFexco()} >
                                    Pedido Fexco
                                </button>
                            </div>}
                            {hayPedidos && <div className='container-fluid'>
                                <button className='btn w-100 rounded-pill text-white botonNaranja'
                                    onClick={() => navigate("/misReservas", { replace: true })} >
                                    Pedidos Realizados del Dia
                                </button>
                            </div>}
                        </div>
                    </div>
                }
                {config === 1 &&
                    <div className='container-fluid '>
                        <div className='text-center textInfo py-2'>
                            ¿Seleccione la ciudad?
                        </div>
                        <div className="btn-group w-100 " role="group" >
                            <button type="button" onClick={() => { recogeraRegional('990') }}
                                style={{ borderTopLeftRadius: '13px', borderBottomLeftRadius: '13px' }}
                                className={`btn border-end border-2 ${regional == '990' ? 'botonNaranja text-light' : 'botonBlanco'}`}>COCHABAMBA</button>
                            <button type="button" onClick={() => { recogeraRegional('991') }}
                                style={{ borderTopRightRadius: '13px', borderBottomRightRadius: '13px' }}
                                className={`btn border-start border-2 ${regional == '991' ? 'botonNaranja text-light' : 'botonBlanco'}`}>SANTA CRUZ</button>
                        </div>
                        <div className='text-center textInfo py-2'>
                            ¿De que sucursal recogerá?
                        </div>
                    </div>}
                {config === 1 && regional !== '0' &&
                    <div className='overflow-auto container-fluid ' style={{ height: '68vh' }}>
                        <div className='w-100'>
                            {sucursales.map((item) => {
                                if (haySucursal(item) && item.CALLCENTER == null) {
                                    return (
                                        <button className='btn  botonConfiguration rounded-pill w-100 mb-2'
                                            id={item.ID_SUCURSAL} onClick={selectSucursal} key={item.ID_SUCURSAL}>
                                            {item.SUCURSAL.toUpperCase()}
                                        </button>
                                    )
                                }
                            })}
                        </div>
                    </div>
                }
                {config === 2 &&
                    <div className='container-fluid py-1'>
                        <div className='text-center textInicioSub fs-4 mb-4'>
                            Tomaremos su pedido <i className="fa-solid fa-face-smile"></i>
                        </div>
                        <div className='text-center textInicio mb-4'>
                            Por favor dejanos un número de celular.
                        </div>
                        <div className='container-fluid mb-5'>
                            <input type="number"
                                value={telefono}
                                onChange={(e) => setTelefono(e.target.value)}
                                className="form-control textInfo mb-4 borderEdit"
                                placeholder='Número de Celular' id='numeroTelefono' />
                        </div>
                        <div className='container-fluid '>
                            <span className='textInicioSub'>En que Departamento se Encuentra ?</span>
                            <select className="form-select selectorRegional letraSelector text-light text-center" value={regional}
                                onChange={(e) => cambiarRegional(e.target.value)}>
                                <option value='0'>  </option>
                                <option value='990'>Cochabamba</option>
                                <option value='991'>Santa Cruz</option>
                            </select>
                        </div>
                    </div>
                }
                {config === 3 &&
                    <div className='py-2'>
                        <div className='row row-cols-2 g-1'>
                            <div className='col col-8 text-center'>
                                <input type='text'
                                    className='form-control form-control-sm textInfo mb-2 borderEdit'
                                    placeholder='Avenida o calle'
                                    id='callePrincipal'>
                                </input>
                            </div>
                            <div className='col col-4 text-center'>
                                <input type="number"
                                    className="form-control form-control-sm textInfo mb-2 borderEdit"
                                    placeholder='Nro.' id='nroDomicilio' />
                            </div>
                        </div>
                        <input type='text'
                            className='form-control  form-control-sm textInfo mb-2 borderEdit'
                            placeholder='Entre que calles?'
                            id='calleSegundaria'>
                        </input>
                        <textarea type="text"
                            id='direccion'
                            className="form-control textInfo mb-2 borderEdit"
                            placeholder='Mas detalles (Color de puerta, Nombre edificio, nros referencias)'
                            style={{ height: '100px' }} />
                        <div className='text-center textInicio'>
                            Colocar Ubicación en el mapa
                        </div>
                        <MapaLef regional={regional} />
                    </div>}
                {config === 1 &&
                    <div className="text-center floatBotones d-none" style={{ top: '2%', left: '5%' }} onClick={() => { setConfig(0) }}>
                        <img src={`${server}/imagenes/atras.png`} width="60" height="60"></img>
                    </div>
                }
                {config === 4 &&
                    <div className=''>
                        <div className='text-center textInicio mb-1'>
                            Seleccione una dirección, o crea
                        </div>
                        <div className='overflow-auto container-fluid ' style={{ height: '65vh' }}>
                            {direcciones.map((item, index) => {
                                return (
                                    <div className="form-check my-auto checketN mb-2" key={index}>
                                        <input className="form-check-input fs-3" type="radio"
                                            name="flexRadioDefault" id={item.ID_DIRECCION}
                                            defaultChecked={selectDir === item.ID_DIRECCION ? true : false}
                                            onChange={() => { setSelectDir(item.ID_DIRECCION); }} />
                                        <label className="form-check-label selector textMontserratLig w-100 p-1">
                                            {item.DIRECCION}
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
                {config === 5 &&
                    <>
                        <div className='text-center textInicioSub fs-4 mb-1'>
                            Tomaremos su pedido <i className="fa-solid fa-face-smile"></i>
                        </div>
                        <div className='container-fluid mb-1'>
                            <input type="number"
                                value={telefono}
                                onChange={(e) => setTelefono(e.target.value)}
                                className="form-control textInfo borderEdit"
                                placeholder='Número de Celular' id='numeroTelefono' />
                        </div>
                        <div className='text-center textInicio'>
                            Colocar Ubicación en el mapa fexco
                        </div>
                        <div className='mb-1'>
                            <MapaLeaflet regional='990' />
                        </div>
                        <textarea type="text"
                            id='direccion'
                            className="form-control textInfo mb-2 borderEdit"
                            placeholder='Mas detalles ...'
                            style={{ height: '100px' }} />
                    </>
                }
            </div>
            <div className='container-fluid col-sm-12 col-md-6 col-xl-4 fixed-bottom mb-1'>
                <div className='container-fluid rounded-pill text-center m-0 p-0'>
                    {config === 2 && <button className='btn botonNaranja rounded-pill text-white w-100'
                        onClick={() => irADireccion()}>
                        Confirmar <i className="fa-solid fa-circle-arrow-right"></i>
                    </button>}
                    {config === 4 &&
                        <div className='container-fluid text-center'>
                            <div className="btn-group w-100">
                                <button className='btn botonNaranja btn-sm rounded text-white mx-1'
                                    onClick={() => { setNewDir(true); setConfig(3) }}>
                                    Nueva Ubicación <i className="fa-solid fa-circle-plus fa-xl"></i>
                                </button>
                                <button className='btn botonBlanco btn-sm rounded text-light mx-1'
                                    onClick={() => seleccionarUbicacion()}>
                                    Usar Ubicación Seleccionada <i className="fa-solid fa-circle-check"></i>
                                </button>
                            </div>
                        </div>
                    }
                    {config === 3 &&
                        <button className='btn botonNaranja rounded-pill text-white mb-2 w-100'
                            onClick={() => guardarLocalizacion()}>
                            Confirmar <i className="fa-solid fa-circle-arrow-right"></i>
                        </button>
                    }
                    {config === 5 &&
                        <button className='btn botonNaranja rounded-pill text-white mb-2 w-100'
                            onClick={() => guardarLocalizacionFexco()}>
                            Confirmar <i className="fa-solid fa-circle-arrow-right"></i>
                        </button>
                    }
                </div>
            </div>
            <Modal show={showNoti} contentClassName="bg-transparent "
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header bsPrefix="modal-header m-0 p-0">
                    <div className='verPedidoButon w-100 text-center my-auto py-1'
                        style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
                        <div className='text-white textTitle'>NOTIFICACIÓN</div>
                    </div>
                </Modal.Header>
                <Modal.Body bsPrefix="modal-body m-0 p-0 bg-light">
                    <div className='container-fluid py-4'>
                        <div className='row row-col-sm-1 row-cols-md-2 g-0'>
                            <div className='col my-auto'>
                                <img src={`${server}/imagenes/qrinfo.png`} className='img-fluid fa-fade' height={50}></img>
                            </div>
                            <div className='col my-auto'>
                                <p className='textInfo text-center mb-1'>Solo se podrá tomar su pedido</p>
                                <p className='textTitle text-center'>por medio de pago QR</p>
                                <p className='textInfo text-center mb-1'>Costo del delivery sera aparte.</p>
                                <p className='textTitle text-center'>{`La demora sera aproximadamente de ${utilsRecervas.getDemoraDelivery()} minutos`}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer bsPrefix="modal-footer text-center m-0 p-0 mx-auto bg-light w-100">
                    <button className="btn botonPlomo text-light rounded-pill" onClick={() => { setShowNoti(false) }}>
                        Cancelar
                    </button>
                    {localStorage.getItem('tipoPedido') != 'FEXCO' && <button className="btn botonNaranja text-light rounded-pill" onClick={() => { setConfig(2); setShowNoti(false) }}>
                        Continuar
                    </button>}
                    {localStorage.getItem('tipoPedido') == 'FEXCO' && <button className="btn botonNaranja text-light rounded-pill" onClick={() => { setConfig(5); setShowNoti(false) }}>
                        Continuar
                    </button>}
                </Modal.Footer>
            </Modal>
            <MensageInformacion msg={message} show={notificar} callback={callbackNotificador} />
            <MsgAlertas msg={message} show={alerta} callback={() => setAlerta(false)} />
            <PopupLoader loading={loading} />

        </div>
    )
}

export default ConfigInicio
