import React, { useEffect, useRef, useState } from 'react'
import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import msgToask from '../utils/msgToask';
const server = process.env.REACT_APP_SERVER

function MapaLef(props) {
    const {regional} = props;
    const [location,setLocation] = useState(regional=='990'?{"lat":-17.37895881408853,"lng":-66.1592442699218}:{"lat":-17.78034876971341,"lng":-63.17976816267903})
    const mapa = useRef(null);
    const markerRef = useRef(null);
    function whenClicked(e) {
        var infoUser = localStorage.getItem('infUser');
        console.log(infoUser);
        if (infoUser!=undefined){
            infoUser = JSON.parse(infoUser);
            infoUser.latitud = e.latlng.lat;
            infoUser.longitud = e.latlng.lng;
            localStorage.setItem('infUser',JSON.stringify(infoUser));
        }else{
            localStorage.setItem('infUser',JSON.stringify({"latitud":e.latlng.lat,"longitud":e.latlng.lng}))
        }
        markerRef.current.setLatLng(e.latlng);
    }
    useEffect(() => {
        var info = JSON.parse(localStorage.getItem('locate'));
        if (info !== null) {
            setLocation({"lat":info.lat,"lng":info.lng});
        } else {
            if (regional === '990') {
                setLocation({"lat":-17.37895881408853,"lng":-66.1592442699218});
            } else {
                setLocation({"lat":-17.78034876971341,"lng":-63.17976816267903});
            }
        }
    }, [])
    useEffect(() => {
        if (mapa.current == null) {
            var map = L.map('mapaLeaflet',
                {
                    center: [location.lat, location.lng],
                    zoom: 16,
                    maxZoom: 22
                }
            )
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OPM</a>'
            }).addTo(map);
            markerRef.current = L.marker([location.lat, location.lng],
                {
                    title: 'Mi Pedido', icon: L.icon({
                        iconUrl: `${server}/imagenes/marker.png`,
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                    })
                }).addTo(map);
            map.on('click', whenClicked);
            mapa.current = map;
        }
    }, [])
    return (
        <div id='mapaLeaflet' className='w-100 mapStyle'></div>
    )
}

export default MapaLef