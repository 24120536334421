import React, { useEffect, useRef, useState } from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import { GoogleMap, useLoadScript, MarkerF, useJsApiLoader, PolygonF } from '@react-google-maps/api';
import msgToask from '../utils/msgToask';
const server = process.env.REACT_APP_SERVER;

function MapaPrueba(props) {
    const { regional } = props;
    const [limite, setLimite] = useState({});
    const [poligonos, setPoligonos] = useState({});
    const refMap = useRef(null);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBwhfEN2_agKFz-xNnQg-75q56U78FDKds',
    });
    const { isLoadedw, loadErrorw } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyBwhfEN2_agKFz-xNnQg-75q56U78FDKds",
    })
    const [latitud, setLatitud] = useState(0);
    const [longitud, setLongitud] = useState(0);
    const [latitudC, setLatitudC] = useState(0);
    const [longitudC, setLongitudC] = useState(0);
    function clickMapa(dato) {
        console.log(dato.latLng.lat(), dato.latLng.lng())
        /*const mapCenter = refMap.current.getCenter();
        console.log(mapCenter)*/
        setLatitud(dato.latLng.lat());
        setLongitud(dato.latLng.lng());
        var ifn = JSON.parse(localStorage.getItem('infUser'))
        ifn.latitud = dato.latLng.lat();
        ifn.longitud = dato.latLng.lng();
        localStorage.setItem('infUser', JSON.stringify(ifn))
    }

    const options = {
        mapTypeControl: false,
        streetViewControl: true,
        fullscreenControl: true,
    };
    function RenderPoligonos() {
        poligonos.features.map((item, index) => {
            const innerCoords1 = [];
            item.geometry.coordinates[0].map((item) => innerCoords1.push({ lat: item[1], lng: item[0] }))
            console.log(innerCoords1)
            return (
                <PolygonF
                    key={index}
                    path={innerCoords1}
                    options={{
                        strokeColor: '#94FD24',
                        clickable: false,
                        draggable: false,
                        editable: false,
                        geodesic: false,
                    }}>
                </PolygonF>
            )
        })
    }
    function RenderRegion() {
        const innerCoords1 = [];
        limite.features[0].geometry.coordinates[0].map((item) => innerCoords1.push({ lat: item[1], lng: item[0] }))
        return (
            <PolygonF
                onClick={(e) => clickMapa(e)}
                path={innerCoords1}
                options={{
                    strokeColor: '#fc1e0d',
                    clickable: true,
                    draggable: false,
                    editable: false,
                    geodesic: false,
                }}>
            </PolygonF>
        )
    }
    function getGeometria() {
        fetch(`${server}/json/geometria.geojson`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setLimite(data);
            })
            .catch(err => {
                msgToask.msgError(err.message)
            });
        fetch(`${server}/json/empresas.geojson`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setPoligonos(data);
            })
            .catch(err => {
                msgToask.msgError(err.message)
            });
    }
    useEffect(() => {
        getGeometria();
        var info = JSON.parse(localStorage.getItem('locate'));
        if (info !== null) {
            setLatitud(info.lat);
            setLongitud(info.lng);
        } else {
            if (regional === '990') {
                setLatitud(-17.37895881408853);
                setLongitud(-66.1592442699218);
                setLatitudC(-17.37895881408853);
                setLongitudC(-66.1592442699218);
            } else {
                setLatitud(-17.78034876971341);
                setLongitud(-63.17976816267903);
                setLatitudC(-17.78034876971341);
                setLongitudC(-63.17976816267903);
            }
        }
    }, [])
    if (!isLoaded) return <ClipLoader color='#fa7210' loading={true} size={150} />;
    return (
        <GoogleMap
            ref={refMap}
            zoom={15}
            center={{ lat: latitud, lng: longitud }}
            clickableIcons={false}
            options={options}
            mapContainerClassName='w-100 mapCompleto'>
            <MarkerF
                position={{ lat: latitud, lng: longitud }}
                title='Pedido' 
                options={{
                    tooltip: "Mi pedido",
                }}
            />
            {limite.features != undefined && RenderRegion()}
            {poligonos.features != undefined && poligonos.features.map((item, index) => {
                const innerCoords1 = [];
                item.geometry.coordinates[0].map((item) => innerCoords1.push({ lat: item[1], lng: item[0] }))
                const colores = ['#FD24F3', '#FD9324', '#94FD24', '#24F8FD',
                    '#2450FD', '#A424FD', '#FF1111', ' #900C3F', '#EFE9E9',
                    '#949BC3', '#0E195A', '#CB00FF', '#6AC3EC', '#17FDA0',
                    '#E2FD17', '#FF854B', '#FF9D78']
                return (
                    <PolygonF
                        key={index}
                        path={innerCoords1}
                        options={{
                            strokeColor: colores[index],
                            clickable: false,
                            draggable: false,
                            editable: false,
                            geodesic: false,
                            icons: [{
                                icon: './img/1CuartoPollo.png',
                                offset: '0',
                                repeat: '10px'
                            }]
                        }}>
                    </PolygonF>
                )
            })}
        </GoogleMap>
    )
}

export default MapaPrueba
